import cx from "classnames";
import { Table } from "@radix-ui/themes";
import { CellGenerator } from "../../explore";
import { TicketScore } from "../model";
import exploreStyles from "../../explore/styles.module.scss";
import styles from "../styles.module.scss";

const SharedReportTicketScoreTable = ({
  data,
}: {
  data: TicketScore[];
}) => {
  return (
    <Table.Root className={styles.ticketScoreTable}>
      <Table.Header className={exploreStyles.tableHeader}>
        <Table.Row className={exploreStyles.theader}>
          <Table.ColumnHeaderCell className={cx(exploreStyles.thcell, styles.ticketScoreTableHeader)}>Ticket</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell className={cx(exploreStyles.thcell, styles.ticketScoreTableHeader)} width={"120px"}>TTR</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell className={cx(exploreStyles.thcell, styles.ticketScoreTableHeader)} width={"90px"}>B&F</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell className={cx(exploreStyles.thcell, styles.ticketScoreTableHeader)} width={"120px"}>Avg. QA Score</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body className={exploreStyles.tbody}>
        {data.map((ticket) => {
          return (
            <Table.Row>
              <Table.Cell>
                <div>
                  {ticket.subject}
                </div>

                <div className={styles.ticketNo}>
                  {`#${ticket.id.split("::")[1]}`}
                </div>
              </Table.Cell>

              <Table.Cell>
                <CellGenerator k={"avgTimeToResolve"} value={ticket.ttrMinutes} view={"topics"} />
              </Table.Cell>

              <Table.Cell>
                <CellGenerator k={"avgBackAndForth"} value={ticket.backAndForth} view={"topics"} />
              </Table.Cell>

              <Table.Cell>
                <CellGenerator k={"avgQaScore"} value={ticket.avgQaScore} view={"topics"} />
              </Table.Cell>
            </Table.Row>
          );
        })}   
      </Table.Body>
    </Table.Root>
  );
};

export default SharedReportTicketScoreTable;