import { Eye } from "lucide-react";
import { params } from "../../../params";
import { useAuthorizedMutation } from "../../../api";
import styles from "../styles.module.scss";
import { useEffect } from "react";
import { getSharedReportUrl } from "../../../routes/sharedReport";

const GenerateReport: React.FC<{ id: string, dateFrom?: string, dateTo?: string }> = ({ id, dateFrom, dateTo  }) => {
  const {mutate: generateReport, data: report, isLoading} = useAuthorizedMutation({
    url: `${params.API_URL}/platform/sampling/sample-report/${id}`,
    method: "POST",
  });

  const handleGenerateReport = () => {
    if (!dateFrom || !dateTo) return;

    generateReport({ dateFrom, dateTo });
  };

  useEffect(() => {
    if (report) {
      const reportId = report.s3Url.split('/')[3].replace(".json", "");
      const url = getSharedReportUrl(reportId);
      window.open(url, "_blank");
    }
  }, [report]);

  if (!dateFrom || !dateTo) return null;

  return (
    <button
      className={styles.linkToTicket}
      onClick={handleGenerateReport}
    >
      <Eye />
      See Report
    </button>
  );
};

export default GenerateReport;
