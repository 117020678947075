import React, { useContext } from "react";
import { CssBaseline, GeistProvider, Page, Themes } from "@geist-ui/core";
import "../../theme.scss";
import { ThemeContext } from "../Theme";

export const GeistContainer = ({ children }: { children: React.ReactNode }) => {
  const { isDarkMode } = useContext(ThemeContext);

  const myDarkTheme = Themes.createFromDark({
    type: "myDarkTheme",
  });

  const myLightTheme = Themes.createFromLight({
    type: "myLightTheme",
  });

  const backgorundColor = isDarkMode ? "#121212" : "#ffffff";

  const darkModePalette = {
    background: backgorundColor,
    accents_1: "#EDEDED",
    accents_3: "#8F8F8F",
    errorLight: "#FF6166",
    successLight: "#52A462",
  };

  const lightModePalette = {
    background: backgorundColor,
    accents_1: "#414141",
    accents_3: "#8F8F8F",
    errorLight: "#FF6166",
    successLight: "#52A462",
  };

  const myTheme = Themes.create(isDarkMode ? myDarkTheme : myLightTheme, {
    type: "myTheme",
    palette: isDarkMode ? darkModePalette : lightModePalette,
  });

  return (
    <GeistProvider themeType="myTheme" themes={[myTheme]}>
      <CssBaseline />
      <Page
        style={{
          background: backgorundColor,
          margin: 0,
          width: "100%",
          height: "fit-content",
          padding: "0",
        }}
      >
        {children}
      </Page>
    </GeistProvider>
  );
};
