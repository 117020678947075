import React, { ReactNode, useContext } from "react";
import cx from "classnames";

import styles from "./styles.module.scss";
import { ThemeContext } from "../../Theme";

export enum EBadgeMode {
  DEFAULT = "default",
  GREEN = "green",
  PURPLE = "purple",
  RED = "red",
  YELLOW = "yellow",
  BLUE = "blue",
  PRETTY = "pretty",
  LOAD = "load",
}

export const GeistBadge = ({
  children,
  mode = EBadgeMode.DEFAULT,
  smaller = false,
  bigger = false,
  onClick = () => {},
}: {
  children: ReactNode;
  mode?: EBadgeMode;
  smaller?: boolean;
  bigger?: boolean;
  onClick?: () => void;
}) => {
  const { isDarkMode } = useContext(ThemeContext);
  return (
    <div
      className={cx(
        styles.geistBadge,
        {
          [styles.pointer]: !!onClick,
          [styles[mode]]: mode !== EBadgeMode.DEFAULT,
          [styles.smaller]: smaller,
          [styles.bigger]: bigger,
          [styles.light_mode]: !isDarkMode,
        },
        mode.toLowerCase()
      )}
      onClick={() => onClick()}
    >
      {children}
    </div>
  );
};
