import { useAuthorizedMutation, useAuthorizedQuery } from "..";
import { params } from "../../params";

export const useExplore = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/explorer/explore`,
    method: "POST",
  });
};
export const useExploreTickets = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/explorer/tickets`,
    method: "POST",
  });
};
export const useExploreValues = (enabled: boolean) => {
  return useAuthorizedQuery({
    queryKey: `useExploreValues`,
    url: `${params.API_URL}/platform/explorer/values`,
    method: "GET",
    enabled,
  });
};
