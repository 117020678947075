import React, { createContext, useContext, useState } from "react";
import { Theme } from "@radix-ui/themes";

type ThemeContextType = {
  isDarkMode: boolean;
  toggleTheme: () => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  isDarkMode: false,
  toggleTheme: () => {},
});

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const QuackTheme: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <Theme
        accentColor="yellow"
        grayColor="slate"
        panelBackground="solid"
        radius="large"
        appearance={isDarkMode ? "dark" : "light"}
      >
        {children}
      </Theme>
    </ThemeContext.Provider>
  );
};
