import { DropdownMenu } from "@radix-ui/themes";
import React, { useContext } from "react";
import { categoriesMapper } from "../lib";
import { FilterContext } from "../context";
import { CheckIcon } from "@radix-ui/react-icons";

export const CategoriesDropDown = ({ options }: { options: string[] }) => {
  const { handleSlicer, getValueByKey, handleFilter } =
    useContext(FilterContext);

  const values = getValueByKey("categories");

  const handleSlice = (option: string) => {
    const fields = values?.split(",");
    let newFields = [];
    if (fields?.includes(option)) {
      newFields = fields?.filter((o) => o !== option);
    } else {
      newFields = [...(fields ?? []), option];
    }

    handleFilter("categories", newFields.join(","));

    handleSlicer(
      "stringSliceBy",
      "categories",
      "or",
      newFields.map((o) => {
        return {
          type: "category",
          operation: "eq",
          value: o,
        };
      })
    );
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        Category
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        {options
          ?.filter((o) => o !== "other")
          .map((option) => {
            return (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={() => handleSlice(option)}
              >
                {
                  categoriesMapper[option as keyof typeof categoriesMapper]
                    ?.icon
                }
                {categoriesMapper[option as keyof typeof categoriesMapper]
                  ?.label ?? "Unknown"}
                {values?.includes(option) && <CheckIcon />}
              </DropdownMenu.Item>
            );
          })}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
