import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import {
  useExplore,
  useExploreTickets,
  useExploreValues,
} from "../../api/useExplore";
import dayjs from "dayjs";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CalendarIcon,
  ChevronDownIcon,
  Component2Icon,
  LoopIcon,
  PlusIcon,
  Cross1Icon,
  ChevronRightIcon,
} from "@radix-ui/react-icons";
import {
  Badge,
  Button,
  DropdownMenu,
  Separator,
  Skeleton,
  Table,
  Spinner,
  Tooltip,
  IconButton,
} from "@radix-ui/themes";
import { LLM } from "./actions/llm";
import styles from "./styles.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { exploreLevel1Table, exploreTicketsLevelTable } from "./stractures";
import {
  Agent,
  CategoryName,
  Channel,
  KbCoverage,
  QAScore,
  Status,
  TicketOverview,
  Trend,
  TTR,
} from "./cells";
import { ThemeContext } from "../../components/Theme";
import { TooltipGenerator } from "./tooltips";
import { SentimentDropDown } from "./slicers/sentiment";
import { QAScoreDropDown } from "./slicers/qaScore";
import { OutliersDropDown } from "./slicers/outliers";
import { CategoriesDropDown } from "./slicers/categories";
import { AgentsDropDown } from "./slicers/agents";
import { CustomersDropDown } from "./slicers/customers";
import { TicketVolumeDropDown } from "./slicers/ticketVolume";
import { TTRDropDown } from "./slicers/ttr";
import { ChannelDropDown } from "./slicers/channel";
import { ResponseTimeDropDown } from "./slicers/responseTime";
import { BackAndForthDropDown } from "./slicers/backAndForth";
import { filters, TOperation } from "./types";
import { FilterContext, FilterProvider } from "./context";
import { TopicsDropDown } from "./slicers/topics";
import { Search } from "./actions/search";
import { SliceStringDropDown } from "./slicers/stringSlice";
import { Keyword } from "./actions/keyword";
import { Users } from "lucide-react";

type Views = "topics" | "categories" | "teams" | "customers";

const translateColumnsToRows = (
  columns: {
    name: string;
    key: string;
    value: any;
  }[]
) => {
  const rows: any = {};

  columns?.forEach((column) => {
    column.value.forEach((item: any) => {
      const id = item.id || item.topicId;
      if (!rows[id]) {
        rows[id] = { id, name: item.name || item.topicName };
      }
      rows[id][column.name] = item[column.name];
    });
  });

  return Object.values(rows);
};

export const CellGenerator = ({
  k,
  value,
  view,
}: {
  k: string;
  value: any;
  view: "topics" | "categories" | "teams" | "customers";
}) => {
  if (value === "undefined") {
    return <>-</>;
  }

  if (k === "overview") {
    return <TicketOverview value={value} />;
  }

  if (view === "categories") {
    if (k === "name") {
      return <CategoryName value={value} />;
    }
  }

  if (k === "kbCoverage") {
    return <KbCoverage value={value} />;
  }

  if (k === "category") {
    return <CategoryName value={value} />;
  }

  if (k === "status") {
    return <Status value={value} />;
  }

  if (k === "agent") {
    return <Agent value={value} />;
  }

  if (k === "avgBackAndForth") {
    if (!value) {
      return <>-</>;
    }

    return (
      <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
        <LoopIcon />
        {value}
      </div>
    );
  }

  if (k === "trend") {
    return <Trend value={value} />;
  }

  if (k === "avgTimeToResolve" || k === "ttr") {
    return <TTR value={value} />;
  }

  if (k === "resolutionRate") {
    return <> {value ? `${(value * 100).toFixed(0)}%` : "-"}</>;
  }

  if (k === "avgQaScore") {
    return <QAScore value={value} />;
  }

  if (k === "topic") {
    return (
      <Badge color="gray" size="2">
        {value}
      </Badge>
    );
  }

  if (k === "channel") {
    return <Channel value={value} />;
  }

  if (k === "team") {
    return (
      <div className={styles.team}>
        <Users />
        {value}
      </div>
    );
  }

  if (value === "undefined") {
    return <>-</>;
  }

  return <>{value ?? "-"}</>;
};

const SlicedBy = ({ isLoading, ticketsCount, groupBy, exploreValues }: any) => {
  const { sliceSelected, handleFilter, deleteSlicerByKey } =
    useContext(FilterContext);

  const keys = Object.keys(sliceSelected)
    .map((key) => key)
    ?.filter((key) => key !== "t");

  const FullDropDown = useCallback(
    ({ children }: { children: ReactNode }) => {
      return (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>{children}</DropdownMenu.Trigger>
          <DropdownMenu.Content
            variant="solid"
            color="gray"
            highContrast
            size="1"
          >
            <SentimentDropDown />
            <QAScoreDropDown />
            {/* <OutliersDropDown /> */}
            <DropdownMenu.Separator />
            {groupBy !== "categories" && (
              <CategoriesDropDown options={exploreValues?.categories ?? []} />
            )}
            {groupBy !== "topics" && (
              <TopicsDropDown options={exploreValues?.topics ?? []} />
            )}

            <AgentsDropDown options={exploreValues?.agents ?? []} />
            {/* <CustomersDropDown /> */}
            <DropdownMenu.Separator />
            {/* <TicketVolumeDropDown /> */}
            {/* <TTRDropDown /> */}

            <SliceStringDropDown
              title={"Channels"}
              sliceKey="channel"
              k="channel"
              options={exploreValues?.channels ?? []}
            />
            {/* <ResponseTimeDropDown /> */}
            {/* <BackAndForthDropDown /> */}

            <SliceStringDropDown
              title={"Statuses"}
              sliceKey="status"
              k="status"
              options={exploreValues?.statuses ?? []}
            />
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      );
    },
    [exploreValues, groupBy, isLoading, ticketsCount]
  );

  return (
    <div>
      {keys.length > 0 ? (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button
              color="gray"
              variant="soft"
              highContrast
              size="1"
              className={styles.btnWithBorder}
            >
              <Component2Icon />{" "}
              {` ${
                sliceSelected[keys[0]].charAt(0).toUpperCase() +
                sliceSelected[keys[0]].slice(1)
              } `}
              <Spinner loading={isLoading}>{ticketsCount}</Spinner> Tickets
              <ChevronDownIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            variant="solid"
            color="gray"
            highContrast
            size="1"
          >
            <div
              style={{
                display: "flex",
                gap: "8px",
                flexDirection: "column",
                padding: "4px",
              }}
            >
              {keys.map((k) => {
                return (
                  <Button
                    variant="outline"
                    className={styles.buttonGroup}
                    onClick={(e) => e.preventDefault()}
                  >
                    {k}

                    <IconButton
                      size="1"
                      variant="ghost"
                      onClick={() => {
                        // TBD - should filter it
                        handleFilter(k, "");
                        deleteSlicerByKey(k);
                      }}
                    >
                      <Cross1Icon />
                    </IconButton>
                  </Button>
                );
              })}
              <FullDropDown>
                <Button
                  color="gray"
                  variant="ghost"
                  highContrast
                  size="1"
                  className={styles.smallBtn}
                >
                  <PlusIcon />
                  Add
                </Button>
              </FullDropDown>
            </div>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      ) : (
        <FullDropDown>
          <Button
            color="gray"
            variant="soft"
            highContrast
            size="1"
            className={styles.btnWithBorder}
          >
            <Component2Icon /> All Tickets{" "}
            <Spinner loading={isLoading}>{ticketsCount}</Spinner> Tickets
            <ChevronDownIcon />
          </Button>
        </FullDropDown>
      )}
    </div>
  );
};

const ExploreComponent = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const { isDarkMode } = useContext(ThemeContext);
  const [searchPhrase, setSearchPhrase] = useState("");
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const [fromDate, setFromDate] = useState(dayjs().subtract(14, "days"));
  const [toDate, setToDate] = useState(dayjs());
  const [chosenTimeFrame, setChosenTimeFrame] = useState("Last 14 days");
  const [token, setToken] = useState(false);

  const {
    slicers,
    clearDiveInto,
    groupBy,
    dateRange,
    handleFilter,
    diveIntoTickets,
    view,
    diveInto,
  } = useContext(FilterContext);

  useEffect(() => {
    if (dateRange === "14d") {
      setChosenTimeFrame("Last 14 days");
      setFromDate(dayjs().subtract(14, "days").startOf("day"));
      setToDate(dayjs());
    }
    if (dateRange === "7d") {
      setChosenTimeFrame("Last 7 days");
      setFromDate(dayjs().subtract(7, "days").startOf("day"));
      setToDate(dayjs());
    }
    if (dateRange === "1m") {
      setChosenTimeFrame("Last 1 month");
      setFromDate(dayjs().subtract(1, "month").startOf("day"));
      setToDate(dayjs());
    }
    if (dateRange === "2m") {
      setChosenTimeFrame("Last 2 month");
      setFromDate(dayjs().subtract(2, "month").startOf("day"));
      setToDate(dayjs());
    }
  }, [dateRange]);

  const [sortedBy, setSortedBy] = useState("");
  const [sortDirection, setSortedDirection] = useState<"asc" | "desc">("asc");

  const { data: exploreValues } = useExploreValues(!!user);

  const [ticketsCount, setTicketsCount] = useState<number | null>(null);

  const [data, setData] = useState<any[] | null>(null);

  const { mutateAsync } = useExplore();

  const { mutateAsync: getTickets } = useExploreTickets();

  useEffect(() => {
    getIdTokenClaims().then((claims) => {
      setToken(true);
    });
  }, []);

  useEffect(() => {
    if (user && token) {
      setLoading(true);
      setSortedBy("");
      setSortedDirection("asc");

      if (view === "aggregated") {
        mutateAsync({
          dateFrom: fromDate.format(),
          dateTo: toDate.format(),
          groupBy: groupBy,
          ...slicers,
        }).then((res) => {
          if (res) {
            setTicketsCount(res?.metadata?.ticketCount);

            const columns = res.res
              ?.filter(
                (column: { status: "fulfilled" | "rejected" }) =>
                  column.status === "fulfilled"
              )
              .map((column: { value: any }) => {
                // break it to stractured keys
                let value: { name: string; value: any; key: string } = {
                  name: "",
                  value: [],
                  key: "",
                };

                Object.keys(column.value).map((key) => {
                  if (key === "name") {
                    value.name = column.value[key];
                  } else {
                    value.key = key;
                    value.value = column.value[key];
                  }
                });

                return value;
              });

            if (columns) {
              const rows: any[] = translateColumnsToRows(columns);
              setData(rows.filter((k) => k.id).filter((k) => k.avgVolume));
              setLoading(false);
            }
          }
        });
      } else {
        getTickets({
          dateFrom: fromDate.format(),
          dateTo: toDate.format(),
          groupBy: groupBy,
          page: 1,
          includeComments: true,
          ...slicers,
        }).then((res) => {
          if (res) {
            setTicketsCount(res?.metadata?.ticketCount);

            const columns = res.res
              ?.filter(
                (column: { status: "fulfilled" | "rejected" }) =>
                  column.status === "fulfilled"
              )
              .map((column: { value: any }) => {
                // break it to stractured keys
                let value: { name: string; value: any; key: string } = {
                  name: "",
                  value: [],
                  key: "",
                };

                Object.keys(column.value).map((key) => {
                  if (key === "name") {
                    value.name = column.value[key];
                  } else {
                    value.key = key;
                    value.value = column.value[key];
                  }
                });

                return value;
              });

            if (columns) {
              const rows: any[] = translateColumnsToRows(columns);

              setData(
                rows
                  .filter((k) => k.id)
                  .map((ticket) => {
                    const rawTicket = res.ticketsData.tickets.find(
                      (t: { id: string }) => t.id === ticket.id
                    );

                    return {
                      ...rawTicket,
                      topic: rawTicket?.topic?.topic ?? undefined,
                      agent: rawTicket?.agentAssigned?.name ?? undefined,
                      category: rawTicket?.category?.name ?? undefined,
                      kbCoverage:
                        rawTicket?.kbEvaluation?.coverageLevel ?? undefined,
                      team:
                        rawTicket?.agentAssigned?.agentGroups?.[0]?.name ??
                        undefined,
                      ...ticket,
                      overview: {
                        id: ticket.id.split("::")[1],
                        subject: rawTicket?.subject,
                      },
                    };
                  })
              );
              setLoading(false);
            }
          }
        });
      }
    }
  }, [groupBy, fromDate, toDate, user, token, slicers, view]);

  const handleSortBy = (key: string, direction: "asc" | "desc" = "asc") => {
    if (data) {
      const sortedRows = [...data].sort((a, b) => {
        if (a[key] == null || a[key] === undefined) return 1;
        if (b[key] == null || b[key] === undefined) return -1;
        if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
        if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
        return 0;
      });

      setSortedBy(key);
      setSortedDirection(direction);
      setData(sortedRows);
    }
  };

  return (
    <div className={styles.exploreContainer}>
      <div className={styles.controlRow}>
        <div className={styles.navbar}>
          {diveInto ? (
            <>
              <Button variant="ghost" color="gray" onClick={clearDiveInto}>
                {groupBy}
              </Button>
              <ChevronRightIcon />
              <label>{diveInto.name}</label>
            </>
          ) : (
            <label>{groupBy}</label>
          )}
        </div>

        <LLM data={data} />
      </div>
      <div className={styles.controlRow}>
        <SlicedBy {...{ isLoading, ticketsCount, groupBy, exploreValues }} />

        {/* <Button color="gray" variant="ghost" highContrast size="1">
          <ViewVerticalIcon /> Display
        </Button> */}
      </div>
      <div className={styles.controlRow}>
        <div className={styles.buttonGroup}>
          {/* <Button color="gray" variant="ghost" highContrast size="1">
            <LayersIcon /> Group
          </Button>
          <Button color="gray" variant="ghost" highContrast size="1">
            <MixerHorizontalIcon /> Filter
          </Button> */}
          <Search {...{ searchPhrase, setSearchPhrase }} />
          <Keyword />
        </div>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button
              color="gray"
              variant="soft"
              size="1"
              className={styles.btnWithBorder}
              highContrast
            >
              {chosenTimeFrame}
              <ChevronDownIcon />
              <Separator orientation="vertical" className={styles.seperator} />
              <CalendarIcon />
              {`${fromDate.format("MMM DD")}-${toDate.format("MMM DD")}`}
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content variant="soft" color="gray">
            <DropdownMenu.Item
              shortcut=""
              onClick={() => {
                handleFilter("t", "7d");
              }}
            >
              Last 7 days
            </DropdownMenu.Item>
            <DropdownMenu.Item
              shortcut=""
              onClick={() => {
                handleFilter("t", "14d");
              }}
            >
              Last 14 days
            </DropdownMenu.Item>
            <DropdownMenu.Item
              shortcut=""
              onClick={() => {
                handleFilter("t", "1m");
              }}
            >
              Last 1 month
            </DropdownMenu.Item>
            <DropdownMenu.Item
              shortcut=""
              onClick={() => {
                handleFilter("t", "2m");
              }}
            >
              Last 2 months
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
      <div className={styles.tsum}>
        <label>{view === "aggregated" ? groupBy : "Tickets"}</label>
        <Spinner loading={isLoading}>
          <Badge size="1" color="gray" variant="soft">
            {data?.length}
          </Badge>
        </Spinner>
      </div>
      {view === "aggregated" ? (
        <Table.Root className={styles.table}>
          <Table.Header className={styles.tableHeader}>
            <Table.Row className={styles.theader}>
              {exploreLevel1Table.map((value, i) => {
                const isSortedByThis = sortedBy === value.key;

                if (i === 0) {
                  return (
                    <Table.ColumnHeaderCell
                      width={"45%"}
                      className={cx(styles.thcell, {
                        [styles.sortedBy]: isSortedByThis,
                      })}
                      onClick={() =>
                        handleSortBy(
                          value.key,
                          isSortedByThis
                            ? sortDirection === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    >
                      <div>
                        {groupBy}
                        {isSortedByThis && sortDirection === "desc" ? (
                          <ArrowUpIcon />
                        ) : (
                          <ArrowDownIcon />
                        )}
                      </div>
                    </Table.ColumnHeaderCell>
                  );
                }
                return (
                  <Tooltip
                    className={cx(styles.tooltip, {
                      [styles.tooltip_light]: !isDarkMode,
                      [styles.tooltip_dark]: isDarkMode,
                    })}
                    content={<TooltipGenerator type={value.key} />}
                  >
                    <Table.ColumnHeaderCell
                      className={cx(styles.thcell, {
                        [styles.sortedBy]: isSortedByThis,
                      })}
                      onClick={() =>
                        handleSortBy(
                          value.key,
                          isSortedByThis
                            ? sortDirection === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    >
                      <div>
                        {value.label}
                        {isSortedByThis && sortDirection === "desc" ? (
                          <ArrowUpIcon />
                        ) : (
                          <ArrowDownIcon />
                        )}
                      </div>
                    </Table.ColumnHeaderCell>
                  </Tooltip>
                );
              })}
            </Table.Row>
          </Table.Header>

          <Table.Body className={styles.tbody}>
            {data
              ?.filter((values) => {
                return values.name?.toLowerCase().includes(searchPhrase);
              })
              .map((values) => {
                return (
                  <Table.Row
                    className={styles.hoverable}
                    onClick={() => {
                      diveIntoTickets(values.id, values.name);
                    }}
                  >
                    {exploreLevel1Table.map((value) => {
                      return (
                        <Table.Cell>
                          <Skeleton loading={isLoading}>
                            {isLoading ? (
                              "Lorem"
                            ) : (
                              <CellGenerator
                                k={value.key}
                                value={
                                  value.key === "trend"
                                    ? values[value.key]
                                    : `${values[value.key]}`
                                }
                                view={groupBy as Views}
                              />
                            )}
                          </Skeleton>
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table.Root>
      ) : (
        <Table.Root className={styles.table}>
          <Table.Header className={styles.tableHeader}>
            <Table.Row className={styles.theader}>
              {exploreTicketsLevelTable.map((value, i) => {
                const isSortedByThis = sortedBy === value.key;

                if (i === 0) {
                  return (
                    <Table.ColumnHeaderCell
                      width={"25%"}
                      className={cx(styles.thcell, {
                        [styles.sortedBy]: isSortedByThis,
                      })}
                      onClick={() =>
                        handleSortBy(
                          value.key,
                          isSortedByThis
                            ? sortDirection === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    >
                      <div>
                        {value.label}
                        {isSortedByThis && sortDirection === "desc" ? (
                          <ArrowUpIcon />
                        ) : (
                          <ArrowDownIcon />
                        )}
                      </div>
                    </Table.ColumnHeaderCell>
                  );
                }
                return (
                  <Tooltip
                    className={cx(styles.tooltip, {
                      [styles.tooltip_light]: !isDarkMode,
                      [styles.tooltip_dark]: isDarkMode,
                    })}
                    content={<TooltipGenerator type={value.key} />}
                  >
                    <Table.ColumnHeaderCell
                      className={cx(styles.thcell, {
                        [styles.sortedBy]: isSortedByThis,
                      })}
                      onClick={() =>
                        handleSortBy(
                          value.key,
                          isSortedByThis
                            ? sortDirection === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    >
                      <div>
                        {value.label}
                        {isSortedByThis && sortDirection === "desc" ? (
                          <ArrowUpIcon />
                        ) : (
                          <ArrowDownIcon />
                        )}
                      </div>
                    </Table.ColumnHeaderCell>
                  </Tooltip>
                );
              })}
            </Table.Row>
          </Table.Header>

          <Table.Body className={styles.tbody}>
            {data?.map((values) => {
              return (
                <Table.Row>
                  {exploreTicketsLevelTable.map((value) => {
                    return (
                      <Table.Cell>
                        <Skeleton loading={isLoading}>
                          {isLoading ? (
                            "Lorem"
                          ) : (
                            <CellGenerator
                              k={value.key}
                              value={
                                value.key === "overview"
                                  ? values[value.key]
                                  : `${values[value.key]}`
                              }
                              view={groupBy as Views}
                            />
                          )}
                        </Skeleton>
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
      )}
    </div>
  );
};

export const Explore = () => {
  return (
    <FilterProvider>
      <ExploreComponent />
    </FilterProvider>
  );
};
