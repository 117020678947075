import dayjs from "dayjs";
import { SharedReportValues } from "../model";
import styles from "../styles.module.scss";

const shortDateFormat = "MMM DD";

type SharedReportHeaderProps = Pick<
  SharedReportValues, 
  "metadata" | "agentName"
>;

const SharedReportHeader = ({
  metadata: { 
    dateFrom,
    dateTo,
  },
  agentName,
}: SharedReportHeaderProps) => {
  return (
    <div className={styles.sharedReportHeader}>
      <div className={styles.range}>
        {`${dayjs(dateFrom).format(shortDateFormat)} - ${dayjs(dateTo).format(shortDateFormat)}`}
      </div>

      <div className={styles.agentName}>
        {agentName}
      </div>
    </div>
  );
};

export default SharedReportHeader;