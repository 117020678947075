import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import cx from "classnames";
import { useFeatureFlag } from "configcat-react";

import { Redirect } from "../redirect";
import logoImg from "../../assets/logo.png";
import {
  ESessionStorage,
  fetchFromSessionStorage,
} from "../../assets/storage/sessionStorage";
import { GeneralEvents, mixTrackEvent } from "../../assets/mixpanel";

import styles from "./styles.module.scss";
import { ENavigate, PagesIcons } from "./sidebar.types";
import { Button, DropdownMenu } from "@radix-ui/themes";
import { ThemeContext } from "../Theme";
import { ExitIcon, GearIcon, MoonIcon, SunIcon } from "@radix-ui/react-icons";
import { sharedReportRouteUri } from "../../routes/sharedReport";

const unprotected = ["/installation-successfull", "/welcome"];

const reports = [
  // ENavigate.ASSESSMENT,
  ENavigate.SIMULATOR,
  ENavigate.KNOWLEDGE,
  ENavigate.SAMPLING,
];

const tooltipMapper: { [x: string]: string } = {
  [ENavigate.HOME]: "Dashboard",
  [ENavigate.ASSESSMENT]: "Topics",
  [ENavigate.ACTIVATION]: "Activation",
  [ENavigate.SIMULATOR]: "Resolution",
  [ENavigate.KNOWLEDGE]: "Knowledge management",
  [ENavigate.SAMPLING]: "Quality",
  [ENavigate.PERFORMANCE]: "Teams",
  [ENavigate.VOC]: "Categories",
  [ENavigate.CUSTOMERS]: "Customers",
  [ENavigate.SESSIONS_REPORTS]: "Sessions",
};

export const Sidebar = () => {
  const { logout, isAuthenticated, isLoading, user } = useAuth0();
  const navigate = useNavigate();
  const { isDarkMode } = useContext(ThemeContext);
  const { toggleTheme } = useContext(ThemeContext);
  const location = useLocation();
  const [firstTopic, setFirstTopic] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { value: sidebarValues } = useFeatureFlag("sidebar", "", {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });
  const { value: beta } = useFeatureFlag("betaFeatures", "", {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  const explorer = [
    ENavigate.ASSESSMENT,
    ENavigate.VOC,
    ENavigate.PERFORMANCE,
    ENavigate.CUSTOMERS,
  ];

  const handleExploreClick = (page: ENavigate) => {
    // if its in explore now, just change the by
    // if its not explore that move it to explore
    if (location.pathname.includes("explore")) {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set("by", page);

        return newParams;
      });
    } else {
      navigate(`/explore?by=${page}`);
    }
  };

  const handleClick = (page: ENavigate = ENavigate.HOME) => {
    mixTrackEvent(GeneralEvents.MOVE_PAGE_FROM_SIDEBAR, {
      oldPage: location.pathname.split("/")[1],
      newPage: page,
    });
    switch (page) {
      case ENavigate.ACTIVATION:
        navigate(`/${page}/${firstTopic}`);
        break;
      default:
        navigate(`/${page}`);
        break;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const _firstTopic = fetchFromSessionStorage(ESessionStorage.FIRST_TOPIC);

      if (_firstTopic) {
        setFirstTopic(_firstTopic);
        clearInterval(interval);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  if (
    unprotected.includes(window.location.pathname) ||
    location.pathname.includes(sharedReportRouteUri)
  )
    return null;

  if (isLoading)
    return (
      <div className={cx(styles.dark_mode, styles.container)}>
        <div className={styles.loading} />
      </div>
    );

  return (
    <div className={cx(styles.container)}>
      {isAuthenticated ? (
        <>
          <div className={styles.topContainer}>
            <div className={styles.account}>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <div>
                    <Button color="gray" variant="outline" size="2">
                      {user?.owner}
                      <DropdownMenu.TriggerIcon />
                    </Button>
                  </div>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content variant="soft" color="gray">
                  <DropdownMenu.Item
                    shortcut=""
                    onClick={() => navigate("/settings")}
                  >
                    <GearIcon />
                    Settings
                  </DropdownMenu.Item>
                  <DropdownMenu.Item shortcut="" onClick={() => toggleTheme()}>
                    {isDarkMode ? <SunIcon /> : <MoonIcon />}
                    Switch mode
                  </DropdownMenu.Item>
                  <DropdownMenu.Separator />
                  <DropdownMenu.Item
                    shortcut=""
                    onClick={() =>
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      })
                    }
                  >
                    <ExitIcon />
                    Logout
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>

            <div className={styles.pagesContainer}>
              <label>Opportunities</label>
              {reports.map((page: ENavigate) => {
                return (
                  <div
                    key={page}
                    className={cx(styles.pageContainer, {
                      [styles.active]: page.includes(
                        location.pathname.split("/")[1]
                      ),
                    })}
                    onClick={() => handleClick(page)}
                  >
                    {PagesIcons[page]}
                    <span>{tooltipMapper[page]}</span>
                  </div>
                );
              })}
              <hr />
              <label>Explore</label>
              {explorer.map((page: ENavigate) => {
                if (tooltipMapper[page]) {
                  return (
                    <div
                      key={page}
                      className={cx(styles.pageContainer, {
                        [styles.active]: `${location.pathname.split("/")[1]}${
                          location.search
                        }`.includes(page),
                      })}
                      onClick={() => handleExploreClick(page)}
                    >
                      {PagesIcons[page]}
                      <span>{tooltipMapper[page]}</span>
                    </div>
                  );
                }
                // eslint-disable-next-line no-lone-blocks

                return <></>;
              })}
              {[ENavigate.SESSIONS_REPORTS].map((page: ENavigate) => {
                return (
                  <div
                    key={page}
                    className={cx(styles.pageContainer, {
                      [styles.active]: `${location.pathname.split("/")[1]}${
                        location.search
                      }`.includes(page),
                    })}
                    onClick={() => handleClick(page)}
                  >
                    {PagesIcons[page]}
                    <span>{tooltipMapper[page]}</span>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div className={styles.pagesContainer}>
            <PopoverMenu
              options={[{ key: "logout", label: "Logout" }]}
              handleClick={(key) =>
                key === "logout" &&
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              openHover
              handleTriggerClick={() => handleClick(ENavigate.SETTINGS)}
            >
              <div
                className={cx(styles.pageContainer, {
                  [styles.active]:
                    location.pathname.split("/")[1] === ENavigate.SETTINGS,
                })}
              >
                {PagesIcons[ENavigate.SETTINGS]}
                <span>Settings</span>
              </div>
            </PopoverMenu>
          </div> */}
        </>
      ) : (
        <>
          <div className={styles.topContainer}>
            <div className={styles.imageContainer}>
              <img
                src={logoImg}
                alt="Quack Logo"
                onClick={() => handleClick()}
              />
            </div>
          </div>
          <Redirect to="/login" />
        </>
      )}
    </div>
  );
};
