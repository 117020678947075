import React, { Fragment, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import cx from "classnames";
import { createToast } from "vercel-toast";
import { useSearchParams } from "react-router-dom";

import { Card } from "../components/integrations/card";
import {
  useIntegrations,
  useParagonAuthenticator,
} from "./api/useIntegrations";
import { SetupCard } from "../components/integrations/setupCard";
import { TabPanel } from "../components/tabPanel";
import { useCreateMember, useMembers } from "./api/useMembers";
import { MembersList } from "../components/members/list";
import { AddMember } from "../components/members/addMember";
import { Integration, IntegrationType, Member } from "./types";
import { CoPilotConfig } from "../components/config";

import styles from "./styles.module.scss";

const intergrationsMap = [
  {
    type: IntegrationType.TICKET_SYSTEM,
    name: "Ticket system",
    isEnabled: true,
  },
  {
    type: IntegrationType.CHAT,
    name: "Messaging platform",
    isEnabled: true,
  },
  {
    type: IntegrationType.OTHER,
    name: "Other",
    isEnabled: true,
  },
  // {
  //   type: IntegrationType.SDK,
  //   name: "Quack's SDK",
  //   isEnabled: false,
  // },
  // {
  //   type: IntegrationType.CRM,
  //   name: "CRM",
  //   isEnabled: false,
  // },
  // {
  //   type: IntegrationType.PRODUCT_ANALYTICS,
  //   name: "CDP - Product analytics",
  //   isEnabled: false,
  // },
  // {
  //   type: IntegrationType.OBSERVABILITY,
  //   name: "Observability",
  //   isEnabled: false,
  // },
];

export default function AccountSetup() {
  const { user } = useAuth0();
  const {
    data: integrationsData,
    isLoading: isLoadingIntegrations,
    refetch: integrationsRefetch,
  } = useIntegrations(user?.owner, user !== null);
  const [searchParams] = useSearchParams();
  const ticketSystem = searchParams.get("ticketSystem");
  const success = searchParams.get("success");

  const {
    data: membersData,
    isLoading: isLoadingMembers,
    refetch,
  } = useMembers(user?.owner, user !== null);

  const [setupCardIsOpen, setSetupCardOpen] = useState(false);
  const [selectedCardType, setSelectedCardType] =
    useState<IntegrationType | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const { mutate, isLoading } = useCreateMember();
  const { mutate: paragonMutate, data } = useParagonAuthenticator();

  const onClickIntegrationCard = (type: IntegrationType) => {
    setSetupCardOpen(true);
    setSelectedCardType(type);

    if ([IntegrationType.TICKET_SYSTEM, IntegrationType.CHAT].includes(type)) {
      paragonMutate({});
    }
  };

  const onClickTab = (index: number) => {
    setSelectedTab(index);
    setSetupCardOpen(false);
    setSelectedCardType(null);
  };

  const addMember = async (email: string) => {
    if (email) {
      const existingMember = members.find((member) => member.email === email);
      if (existingMember) {
        createToast(`Member ${email} already exists`, {
          type: "dark",
          timeout: 3000, // in 3 seconds
        });
      } else {
        console.log(`Adding member ${email}`);
        // set loading true
        mutate(
          { email },
          {
            onSuccess: () => {
              console.log(`finished adding member ${email}`);
              createToast(`Member ${email} added`, {
                type: "success",
                timeout: 3000, // in 3 seconds
              });
              refetch();
            },
            onError: (error) => {
              console.log(`error adding member ${email}`, error);
              createToast(`Error adding member ${email} : ${error}`, {
                type: "error",
                timeout: 3000, // in 3 seconds
              });
            },
          }
        );
      }
    }
  };

  useEffect(() => {
    if (user && ticketSystem) {
      if (success === "true") {
        createToast(`You have logged to ${ticketSystem} successfully`, {
          type: "success",
          timeout: 5000,
        });
      } else {
        createToast(`Failed to log in to ${ticketSystem}`, {
          type: "error",
          timeout: 5000,
        });
      }
    }
  }, [ticketSystem, success, user]);

  //todo take care of loading state
  if (
    isLoadingIntegrations ||
    !integrationsData ||
    isLoadingMembers ||
    !membersData
  )
    return (
      <div className={styles.loading}>loading...</div>
    );
  const integrations: Integration[] = integrationsData.listIntegrations.items;
  const members: Member[] = membersData.listUsers.items;

  return (
    <Fragment>
      <div className={cx(styles.container)}>
        <div className={styles.cards_container}>
          <p>Hi {user?.name} 👋, happy to see you here</p>
          
          <TabPanel onClick={onClickTab} selectedTabIndex={selectedTab} />

          {selectedTab === 0 && (
            <div>
              {intergrationsMap.map((item, index) => {
                let existingIntegration = integrations.find(
                  (i) => i.type === item.type
                );

                if (item.type === IntegrationType.OTHER) {
                  existingIntegration = integrations.find((i) =>
                    [
                      IntegrationType.CRM,
                      IntegrationType.OBSERVABILITY,
                      IntegrationType.KNOWLEDGE_BASE,
                      IntegrationType.PRODUCT_ANALYTICS,
                    ].includes(i.type)
                  );
                }

                return (
                  <Card
                    key={index}
                    integration={{ ...existingIntegration, ...item }}
                    onClick={() => {
                      onClickIntegrationCard(item.type);
                    }}
                    selected={item.type === selectedCardType}
                  />
                );
              })}
            </div>
          )}
          {selectedTab === 1 && (
            <div className={styles.members_container}>
              <AddMember onClick={addMember} isLoading={isLoading} />
              <MembersList members={members} refetch={refetch} />
            </div>
          )}
          {selectedTab === 2 && <CoPilotConfig />}
        </div>
        <SetupCard
          show={setupCardIsOpen}
          selectedCardType={selectedCardType}
          token={data?.token}
          integrations={integrations}
          refetch={integrationsRefetch}
        />
      </div>
    </Fragment>
  );
}
