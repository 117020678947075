import React from "react";
import { ExternalLinkIcon, LoopIcon, TimerIcon } from "@radix-ui/react-icons";
import { Button } from "@radix-ui/themes";

import styles from "./styles.module.scss";
import { Trend } from "../cells";
import { TrendingUpIcon } from "lucide-react";
import { TrendingUp } from "@geist-ui/icons";

export const TooltipGenerator = ({ type }: { type: string }) => {
  if (type === "avgTimeToResolve") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>
          <TimerIcon />
          Average Time to Resolution
        </div>
        <div className={styles.description}>
          Measures the time from ticket creation to resolution, reflecting
          support efficiency
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "avgVolume") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Volume</div>
        <div className={styles.description}>
          The number of tickets in the selected period of time
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "avgQaScore") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Average QA Score</div>
        <div className={styles.description}>
          {" "}
          Reflects the quality of support provided based on evaluated tickets.
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }

  if (type === "avgBackAndForth") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>
          <LoopIcon />
          Average Back and Forth
        </div>
        <div className={styles.description}>
          Average number of user-agent interactions
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }

  if (type === "resolutionRate") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Resolution</div>
        <div className={styles.description}>
          What percentage of tickets are resolved (closed or solved status)
        </div>
        {/* <Button variant="outline" color="gray" size="1">
      <ExternalLinkIcon />
      Learn more
    </Button> */}
      </div>
    );
  }
  if (type === "trend") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Trend</div>
        <div className={styles.description}>
          Ticket trend indicates changes in volume over time, showing if it's
          rising, falling, or steady, and highlights seasonal patterns or
          emerging issues.
        </div>
        {/* <Button variant="outline" color="gray" size="1">
      <ExternalLinkIcon />
      Learn more
    </Button> */}
      </div>
    );
  }
  return <>{type}</>;
};
