import React from "react";
import {
  AreaChart,
  Area,
  ResponsiveContainer,
  Line,
  LineChart,
  ComposedChart,
} from "recharts";

export const Chart = ({
  data,
  width = 100,
  height = 50,
  disableTrendLine = false,
}: {
  data: { month: number; count: number }[];
  width?: number;
  height?: number;
  disableTrendLine?: boolean;
}) => {
  const calculateTrendLine = (data: { month: number; count: number }[]) => {
    const n = data.length;
    if (n === 0) return [];

    const sumX = data.reduce((acc, point) => acc + point.month, 0);
    const sumY = data.reduce((acc, point) => acc + point.count, 0);
    const sumXY = data.reduce(
      (acc, point) => acc + point.month * point.count,
      0
    );
    const sumX2 = data.reduce(
      (acc, point) => acc + point.month * point.month,
      0
    );

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    return data.map((point) => ({
      month: point.month,
      trend: slope * point.month + intercept,
    }));
  };

  const trendData = calculateTrendLine(data);

  return (
    <ResponsiveContainer width={width} height={height}>
      <>
        <ComposedChart
          data={data.map((d, i) => ({ ...d, trend: trendData[i].trend }))}
          width={width}
          height={height}
        >
          <Area
            type="monotone"
            dataKey="count"
            stroke="#8884d8"
            fill="#8884d8"
          />
          {!disableTrendLine && (
            <Line
              type="monotone"
              dataKey="trend"
              stroke="#8884d8"
              strokeDasharray="3 4 5 2"
              strokeWidth={2}
              dot={false}
            />
          )}
        </ComposedChart>
      </>
    </ResponsiveContainer>
  );
};
