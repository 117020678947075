import { QAScore } from "../../explore/cells";
import { SharedReportValues } from "../model";
import styles from "../styles.module.scss";

type SharedReportTopMetricsProps = Pick<SharedReportValues, "avgScore" | "numberOfTickets">;

const SharedReportTopMetrics = ({ avgScore, numberOfTickets }: SharedReportTopMetricsProps) => {
  return (
    <div className={styles.topMetrics}>
      <div className={styles.item}>
        <div className={styles.label}>Evaluated Tickets</div>
        <div className={styles.value}>{numberOfTickets}</div>
      </div>

      <div className={styles.item}>
        <div className={styles.label}>Avg. QA Score</div>
        <div className={styles.value}>
          <QAScore value={parseFloat(avgScore)} />
        </div>
      </div>
    </div>
  );
};

export default SharedReportTopMetrics;