import { DropdownMenu } from "@radix-ui/themes";
import React, { useContext } from "react";
import { FilterContext } from "../context";
import { CheckIcon } from "@radix-ui/react-icons";

export const SliceStringDropDown = ({
  k,
  title,
  sliceKey,
  options,
}: {
  k: string;
  title: string;
  sliceKey: string;
  options: string[];
}) => {
  const { handleSlicer, getValueByKey, handleFilter } =
    useContext(FilterContext);

  const values = getValueByKey(k);

  const handleSlice = (option: string) => {
    const fields = values?.split(",");
    let newFields = [];
    if (fields?.includes(option)) {
      newFields = fields.filter((o) => o !== option);
    } else {
      newFields = [...(fields ?? []), option];
    }

    handleFilter(k, newFields.join(","));

    handleSlicer(
      "stringSliceBy",
      k,
      "or",
      newFields.map((o) => {
        return {
          type: sliceKey,
          operation: "eq",
          value: o,
        };
      })
    );
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        {title}
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        {options
          .filter((o) => o !== "other")
          .map((option) => {
            return (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={() => handleSlice(option)}
              >
                {option}
                {values?.includes(option) && <CheckIcon />}
              </DropdownMenu.Item>
            );
          })}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
