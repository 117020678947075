export const exploreTicketsLevelTable = [
  {
    label: "Tickets",
    key: "overview",
  },
  {
    label: "Status",
    key: "status",
  },
  {
    label: "Topic",
    key: "topic",
  },
  {
    label: "Agent",
    key: "agent",
  },
  {
    label: "Category",
    key: "category",
  },
  {
    label: "Team",
    key: "team",
  },
  {
    label: "TTR",
    key: "ttr",
  },
  // {
  //   label: "B&F",
  //   key: "avgBF",
  // },
  {
    label: "Channel",
    key: "channel",
  },
  // {
  //   label: "QA Score",
  //   key: "qaScore",
  // },
  // {
  //   label: "Negative Sentiment",
  //   key: "sentiment",
  // },
  {
    label: "KB Coverage",
    key: "kbCoverage",
  },
  // {
  //   label: "Created",
  //   key: "createdAt",
  // },
  // {
  //   label: "Last Update",
  //   key: "updatedAt",
  // },
];

export const exploreLevel1Table = [
  {
    label: "Topics",
    key: "name",
  },
  {
    label: "Volume",
    key: "avgVolume",
  },
  {
    label: "Trend Pattern",
    key: "trend",
  },
  {
    label: "Resolution",
    key: "resolutionRate",
  },
  {
    label: "Avg. TTR",
    key: "avgTimeToResolve",
  },
  {
    label: "Avg. B&F",
    key: "avgBackAndForth",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
  },
];
