import React, { useRef, useEffect, useState, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IoSparklesSharp } from "react-icons/io5";
import cx from "classnames";
import { Tooltip } from "@geist-ui/core";

import { ThemeContext } from "../../../../components/Theme";
import { CoverageLevelType } from "../../index.types";
import {
  EPerformanceType,
  PerformenceBar,
} from "../../../../components/atoms/performenceBar";


import styles from "./styles.module.scss";

export const ArticleRow = ({
  id,
  name,
  articles,
  suggestedArticle,
  usage,
  draft,
  topicCoverage,
}: {
  id: string;
  name: string;
  articles: number;
  suggestedArticle: number;
  usage?: number;
  draft?: number;
  topicCoverage: { [key in CoverageLevelType]: number };
}) => {
  const navigate = useNavigate();
  const nameRef = useRef<HTMLDivElement>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    const checkEllipsis = () => {
      if (nameRef.current) {
        setIsEllipsisActive(
          nameRef.current.scrollWidth > nameRef.current.clientWidth
        );
      }
    };

    checkEllipsis();
    window.addEventListener("resize", checkEllipsis);
    return () => window.removeEventListener("resize", checkEllipsis);
  }, []);

  const coverage = useMemo(() => {
    if (!topicCoverage) return [];
    const sum = Object.values(topicCoverage).reduce((a, b) => a + b, 0);
    const CoverageToPerformance = {
      [CoverageLevelType.FULL]: EPerformanceType.GREEN,
      [CoverageLevelType.PARTIAL]: EPerformanceType.YELLOW,
      [CoverageLevelType.NO_COVERAGE]: EPerformanceType.RED,
    };
    return Object.entries(topicCoverage).map(([key, value]) => ({
      percentage: (value * 100) / sum,
      performance: CoverageToPerformance[key as CoverageLevelType],
    }));
  }, [topicCoverage]);

  return (
    <div
      className={styles.articleRowContainer}
      onClick={() => navigate(`/knowledge-management/${id}`)}
    >
      <div className={styles.topicName} ref={nameRef}>
        {isEllipsisActive ? (
          <Tooltip text={name} placement="topStart">
            {name}
          </Tooltip>
        ) : (
          name
        )}
      </div>
      <div className={styles.topicData}>{articles} Articles</div>
      <div className={styles.suggestedArticle}>
        {suggestedArticle !== 0 && (
          <>
            {suggestedArticle} <IoSparklesSharp />
          </>
        )}
      </div>
      <div className={styles.topicData}>
        <PerformenceBar value={coverage} />
      </div>
    </div>
  );
};
