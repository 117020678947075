import { DropdownMenu } from "@radix-ui/themes";
import React, { useContext } from "react";
import { FilterContext } from "../context";
import { CheckIcon } from "@radix-ui/react-icons";

export const AgentsDropDown = ({ options }: { options: string[] }) => {
  const { handleSlicer, getValueByKey, handleFilter } =
    useContext(FilterContext);

  const values = getValueByKey("agents");

  const handleSlice = (option: string) => {
    const fields = values?.split(",");
    let newFields = [];
    if (fields?.includes(option)) {
      newFields = fields?.filter((o) => o !== option);
    } else {
      newFields = [...(fields ?? []), option];
    }

    handleFilter("agents", newFields.join(","));

    handleSlicer(
      "stringSliceBy",
      "agents",
      "or",
      newFields.map((o) => {
        return {
          type: "agent",
          operation: "eq",
          value: o,
        };
      })
    );
  };
  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        Agents
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        {options.map((option) => {
          return (
            <DropdownMenu.Item
              className="DropdownMenuItem"
              onClick={() => handleSlice(option)}
            >
              {values?.includes(option) && <CheckIcon />}
              {option}
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
