import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Release,
  ReleaseCondition,
  ReleaseConversation,
  ReleaseComment,
  ReleaseStatus,
  Instruction,
  ReleaseConversationFeedback,
  SimulatorChannel,
} from "../types";
import {
  useCreateNewRelease,
  useCreateNewReleaseConversation,
  useCreateSimulator,
  useGetReleaseConversations,
  useGetReleases,
  useGetSimulators,
  usePublishRelease,
  useUpdateRelease,
  useUpdateSimulator,
} from "../../../api/useRelease";
//@ts-ignore
import confetti from "canvas-confetti";

import { useAuth0 } from "@auth0/auth0-react";
import { createToast } from "vercel-toast";
import { useParams } from "react-router-dom";

const aiAgentNames = [
  "ChatBot Charlie",
  "Supportive Sam",
  "Helper Hannah",
  "Agent Alex",
  "Customer Care Casey",
  "Service Sarah",
  "Assistant Andy",
  "Problem Solver Pat",
  "Friendly Fran",
  "Guide Gabe",
  "Navigator Nate",
  "Responder Riley",
  "Solution Sage",
  "Troubleshooter Terry",
  "Aid Ava",
  "Support Specialist Spencer",
  "Helper Harper",
  "Customer Champion Chris",
  "Service Star Sky",
  "Agent Ace",
];

interface ReleaseContextType {
  simulators: {
    id: number;
    name: string;
    releases: Release[];
    channel: SimulatorChannel;
    publishedReleaseId?: number;
  }[];
  releases: Release[];
  relevantRelease: Release | null;
  setReleases: React.Dispatch<React.SetStateAction<Release[]>>;
  releaseConditions: ReleaseCondition[];
  setReleaseConditions: React.Dispatch<
    React.SetStateAction<ReleaseCondition[]>
  >;
  releaseConversations: ReleaseConversation[];
  releaseComments: ReleaseComment[];
  setReleaseComments: React.Dispatch<React.SetStateAction<ReleaseComment[]>>;
  handleUpdateReleaseInstructions: (instructions: any) => void;
  handleUpdateConversation: (object: {
    localConversationId: number;
    message: { role: "user" | "assistant"; content: string };
    topicId: number;
    feedback: ReleaseConversationFeedback;
    feedbackComment?: string;
  }) => void;
  conversationId: number | null;
  releaseId: number | null;
  handlePublish: (instructionsToPublish: Instruction[], cb: () => void) => void;
  handleCreateNewRelease: (simulatorId: number) => void;
  handleCreateSimulator: () => void;
  handleUpdateSimulator: (id: number, name: string) => void;
  setChosenSimulatorId: (id: number) => void;
  chosenSimulatorId: number | null;
}

const ReleaseContext = createContext<ReleaseContextType | undefined>(undefined);

export const useReleases = (): ReleaseContextType => {
  const context = useContext(ReleaseContext);
  if (!context) {
    throw new Error("useReleases must be used within a ReleaseProvider");
  }

  return context;
};

export const ReleaseProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth0();

  const { simulatorId } = useParams();

  const [releases, setReleases] = useState<Release[]>([]);
  const [releaseConditions, setReleaseConditions] = useState<
    ReleaseCondition[]
  >([]);

  const [releaseComments, setReleaseComments] = useState<ReleaseComment[]>([]);
  const [releaseId, setReleaseId] = useState(0);
  const [chosenSimulatorId, setChosenSimulatorId] = useState(0);
  const [conversationId, setConversationId] = useState<number | null>(null);

  const { data: simulators, refetch: refetchSimulators } = useGetSimulators(
    !!user
  );
  const { data, refetch } = useGetReleases(!!user && !!simulatorId);

  const { mutateAsync: createNewRelease } = useCreateNewRelease();
  const { mutateAsync: createSimulator } = useCreateSimulator();
  const { mutateAsync: updateSimulator } = useUpdateSimulator({
    simulatorId: chosenSimulatorId,
  });
  const { mutateAsync: updateRelease } = useUpdateRelease(releaseId);
  const { mutateAsync: publishRelease } = usePublishRelease(
    releaseId,
    +(simulatorId ?? 0)
  );

  useEffect(() => {
    if (simulatorId) {
      setChosenSimulatorId(+simulatorId);
    }
  }, [simulatorId]);

  const handleCreateSimulator = () => {
    createSimulator({
      id: null,
      tenantId: user?.owner,
      name: aiAgentNames[Math.floor(Math.random() * aiAgentNames.length)],
    }).then((res) => {
      // refetchSimulators();
      handleCreateNewRelease(res.id);
    });
  };
  const handleUpdateSimulator = (simulatorId: number, name: string) => {
    updateSimulator({
      id: simulatorId,
      tenantId: user?.owner,
      name: name,
    }).then(() => {
      refetchSimulators();
    });
  };

  const handleCreateNewRelease = (simulatorId: number) => {
    createNewRelease({
      id: null,
      simulatorId,
      releasePolicy: JSON.stringify([
        {
          content: 2,
          subTopic: "",
          topic: "maxBackAndForth",
          type: "general",
        },
        {
          content: "medium",
          subTopic: "",
          topic: "length",
          type: "general",
        },
        {
          content: true,
          subTopic: "",
          topic: "includeLink",
          type: "general",
        },
        {
          content: true,
          subTopic: "",
          topic: "includeOpening",
          type: "general",
        },
      ]),
      tenantId: user?.owner,
      status: "draft",
    }).then((res) => {
      refetch();
      refetchSimulators();
    });
  };
  //   currently we're working on the draft release, later we'll add a list to choose which release
  const relevantRelease = useMemo(() => {
    if (releaseId) {
      return releases?.filter((r) => r.id === +releaseId)?.[0];
    } else {
      return null;
    }
  }, [releases, releaseId]);

  const { mutateAsync: upsertRelease } = useUpdateRelease(
    relevantRelease?.id ?? 0
  );
  const { mutateAsync: upsertConversation } = useCreateNewReleaseConversation(
    relevantRelease?.id ?? 0
  );
  const { data: releaseConversationsData, refetch: refetchConversations } =
    useGetReleaseConversations(!!relevantRelease?.id, relevantRelease?.id ?? 0);

  useEffect(() => {
    if (simulators) {
      const chosenSimulator = simulators.find(
        (s: { id: number }) => s.id === +(simulatorId ?? 0)
      );
      // get the chosen simulator id and set the release of this simulator id
      setReleases(chosenSimulator?.releases ?? []);

      const unpublishedRelease = chosenSimulator?.releases.find(
        (release: Release) => !release.publishedAt
      );

      setReleaseId(unpublishedRelease?.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, simulators, simulatorId]);

  const handleUpdateReleaseInstructions = (
    instructions: Record<string, string[]>
  ) => {
    upsertRelease({
      id: relevantRelease?.id ?? 0,
      releasePolicy: JSON.stringify(instructions),
      tenantId: user!.owner,
      status: "draft",
      simulatorId: +(simulatorId ?? 0),
    });
  };

  const handleUpdateConversation = ({
    localConversationId,
    message,
    topicId,
    feedback,
  }: {
    localConversationId: number;
    message: { role: "user" | "assistant"; content: string };
    topicId: number;
    feedback: "NONE" | "POSITIVE" | "NEGATIVE";
  }) => {
    if (topicId !== 0) {
      upsertConversation({
        releaseConversationId: localConversationId ?? 0,
        comment: message.content,
        topicId: topicId,
        feedback: feedback,
        isAgent: message.role === "assistant",
        feedbackComment: "",
        releaseId: relevantRelease?.id,
      }).then((res) => {
        refetch();
        refetchConversations();
        setConversationId(res.id);
      });
    }
  };

  const handlePublish = (
    instructionsToPublish: Instruction[],
    callback: () => void
  ) => {
    publishRelease({
      // id: releaseId,
      releasePolicy: JSON.stringify(instructionsToPublish),
      // tenantId: user!.owner,
      // status: "published",
      // simulatorId: +(simulatorId ?? 0),
    }).then((res) => {
      callback();
      createToast(`Your AI Agent is published!`, {
        type: "success",
        timeout: 3000,
      });

      confetti({
        particleCount: 300,
        spread: 300,
        origin: { y: 0.5, x: 0.4 },
      });
      refetch();
    });
  };

  return (
    <ReleaseContext.Provider
      value={{
        releases,
        setReleases,
        releaseConditions,
        setReleaseConditions,
        releaseConversations: releaseConversationsData ?? [],
        releaseComments,
        setReleaseComments,
        relevantRelease,
        handleUpdateReleaseInstructions,
        handleUpdateConversation,
        conversationId,
        handlePublish,
        handleCreateNewRelease,
        simulators,
        releaseId,
        handleCreateSimulator,
        handleUpdateSimulator,
        setChosenSimulatorId,
        chosenSimulatorId,
      }}
    >
      {children}
    </ReleaseContext.Provider>
  );
};
