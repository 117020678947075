import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./styles.module.scss";

import { CheckIcon, ClipboardIcon, MagicWandIcon } from "@radix-ui/react-icons";
import { Button, Dialog, Skeleton, Tabs, Text } from "@radix-ui/themes";
//@ts-ignore
import { Converter } from "showdown";

export const LLM = ({ data }: { data: any }) => {
  const { user } = useAuth0();
  const [productSummary, setProductSummary] = useState("");
  const [rndSummary, setRndSummary] = useState("");
  const [supportSummary, setSupportSummary] = useState("");
  const [successSummary, setSuccessSummary] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isCopied, setCopied] = useState(false);

  const [tab, setTab] = useState("");

  useEffect(() => {
    setProductSummary("");
    setRndSummary("");
    setSupportSummary("");
    setSuccessSummary("");
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(productSummary)
      .then(() => {
        console.log("Summary copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
    setCopied(true);
  };

  const fetchExecSummary = async () => {
    setLoading(true);
    try {
      const departments = ["support", "product", "rnd", "success"];
      const mapper = {
        product: setProductSummary,
        rnd: setRndSummary,
        support: setSupportSummary,
        success: setSuccessSummary,
      };
      for (const department of departments) {
        const response = await fetch("https://ai.quack.cx/api/exec-summary", {
          method: "POST",
          headers: {
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "x-quack-token": "5f4dcc3b5aa765d61d8327deb882cf99",
          },
          body: JSON.stringify({
            tenantId: user?.owner, // replace with actual tenant ID
            department, // use the current department
            data: data?.comments ?? data, // assuming 'data' is the data you want to send
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        mapper[department as keyof typeof mapper](result.text);
        setLoading(false);
        console.log(`Exec Summary for ${department}:`, result);
      }
    } catch (error) {
      console.error("Error fetching exec summary:", error);
    }
  };

  const convertor = new Converter({
    simplifiedAutoLink: true,
    excludeTrailingPunctuationFromURLs: true,
    openLinksInNewWindow: true,
    smoothLivePreview: true,
    disableForced4SpacesIndentedSublists: true,
  });

  const convertMarkdownToHTML = (markdown: string) => {
    if (markdown) {
      // return markdown;
      return convertor?.makeHtml(markdown);
    } else {
      return "";
    }
  };

  const values = {
    product: productSummary,
    rnd: rndSummary,
    support: supportSummary,
    success: successSummary,
  };

  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger>
          <Button
            color="gray"
            variant="outline"
            size="1"
            onClick={() => {
              fetchExecSummary().then((res) => {
                console.log(res);
              });
            }}
          >
            <MagicWandIcon /> Summarize
          </Button>
        </Dialog.Trigger>

        <Dialog.Content maxWidth="760px">
          <Tabs.Root
            value={tab}
            onValueChange={(val) => {
              setTab(val);
            }}
          >
            <Tabs.List>
              <Tabs.Trigger value="support">Support report</Tabs.Trigger>
              <Tabs.Trigger value="product">Product Report</Tabs.Trigger>
              <Tabs.Trigger value="rnd"> R&D report</Tabs.Trigger>
              <Tabs.Trigger value="success">Success report</Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
          {successSummary && supportSummary && productSummary && rndSummary ? (
            <>
              <div className={styles.topbarInDialog}>
                <Button
                  color={isCopied ? "green" : "gray"}
                  variant="ghost"
                  onClick={handleCopy}
                >
                  {isCopied ? <CheckIcon /> : <ClipboardIcon />}
                  Copy
                </Button>
              </div>

              <Dialog.Description>
                <Text size="2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: convertMarkdownToHTML((values as any)[tab]),
                    }}
                  />
                </Text>
              </Dialog.Description>
            </>
          ) : (
            <>
              <Skeleton loading={isLoading}>
                <p className="text-gray-500">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
              </Skeleton>
              <Skeleton loading={isLoading}>
                <p className="text-gray-500">
                  Sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.
                </p>
              </Skeleton>
              <Skeleton loading={isLoading}>
                <p className="text-gray-500">
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </Skeleton>
              <Skeleton loading={isLoading}>
                <p className="text-gray-500">
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur.
                </p>
              </Skeleton>
              <Skeleton loading={isLoading}>
                <p className="text-gray-500">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
              </Skeleton>
            </>
          )}
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
