import React, { createContext, useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { filters, TOperation, View } from "../types";

interface FilterContextType {
  slicers: filters;
  groupBy: "topics" | "categories" | "teams" | "customers";
  dateRange: "7d" | "14d" | "1m" | "2m";
  sliceSelected: any;
  setSlicers: React.Dispatch<React.SetStateAction<filters>>;
  handleFilter: (key: string, value: string) => void;
  deleteSlicerByKey: (key: string) => void;
  handleSlicer: (
    type: keyof filters,
    key: string,
    operation: TOperation,
    values: any
  ) => void;
  //   handleSlicedSelection: (key: string, value: any) => void;
  getValueByKey: (key: string) => string | null;
  view: View;
  diveIntoTickets: (value: string, name: string) => void;
  diveInto: { value: string; groupBy: string; name: string } | null;
  clearDiveInto: () => void;
}

export const FilterContext = createContext<FilterContextType>({
  slicers: {
    stringSliceBy: { and: [] },
    numericSliceBy: { and: [] },
  },
  groupBy: "topics",
  dateRange: "14d",
  sliceSelected: {},
  //   handleSlicedSelection: () => {},
  handleSlicer: () => {},

  handleFilter: () => {},
  deleteSlicerByKey: (key: string) => {},
  setSlicers: () => {},
  getValueByKey: () => {
    return "";
  },
  view: "aggregated",
  diveIntoTickets: () => {},
  clearDiveInto: () => {},

  diveInto: null,
});

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilter must be used within a FilterProvider");
  }
  return context;
};

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [view, setView] = useState<View>("aggregated");
  const [diveInto, setDiveInto] = useState<{
    name: string;
    value: string;
    groupBy: "topics" | "categories" | "teams" | "customers";
  } | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sliceSelected, setSliceSelected] = useState<{ [key: string]: any }>({
    t: "14d",
  });
  const [slicers, setSlicers] = useState<filters>({
    stringSliceBy: { and: [] },
    numericSliceBy: { and: [] },
  });
  const [groupBy, setGroupBy] = useState<
    "topics" | "categories" | "teams" | "customers"
  >("topics");
  const [dateRange, setDateRange] = useState<"7d" | "14d" | "1m" | "2m" | null>(
    null
  );

  const handleFilter = (key: string, value: string) => {
    setSliceSelected((prev) => {
      const newSliceSelected = { ...prev };
      if (value) {
        newSliceSelected[key] = value;
      } else {
        delete newSliceSelected[key];
      }
      return newSliceSelected;
    });
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      if (value) {
        newParams.set(key, value);
      } else {
        newParams.delete(key);
      }
      return newParams;
    });
  };

  const getValueByKey = (key: string) => {
    return searchParams.get(key);
  };

  const diveIntoTickets = (value: string, name: string) => {
    setDiveInto({
      groupBy,
      value: value,
      name: name,
    });

    const m = {
      categories: "category",
      topics: "topic",
      teams: "teams",
      customers: "organization",
    };

    handleSlicer("stringSliceBy", "dive", "and", [
      {
        type: m[groupBy],
        operation: "eq",
        value: String(name),
      },
    ]);

    setView("tickets");
  };
  //   const handleSlicedSelection = (key: string, value: string) => {
  //     setSliceSelected((prev) => {
  //       const newSliceSelected = { ...prev };
  //       if (value) {
  //         newSliceSelected[key] = value;
  //       } else {
  //         delete newSliceSelected[key];
  //       }
  //       return newSliceSelected;
  //     });
  //   };

  useEffect(() => {
    // Logic to update slicers based on searchParams
    // This is a placeholder and should be replaced with actual logic
    const newSlicers = { ...slicers };

    const paramsObj: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      paramsObj[key] = value;
    });

    if (!paramsObj.t) {
      handleFilter("t", "14d");
    }

    // Example: Update slicers based on searchParams
    // newSlicers.stringSliceBy.and = searchParams.get("stringSliceBy") || [];
    // newSlicers.numericSliceBy.and = searchParams.get("numericSliceBy") || [];
    setDateRange(paramsObj.t as "7d" | "14d" | "1m" | "2m");
    setGroupBy(paramsObj.by as "topics" | "categories" | "teams" | "customers");
    setSlicers({
      ...newSlicers,
    });
  }, [searchParams]);

  useEffect(() => {
    clearDiveInto();
  }, [groupBy]);

  const clearDiveInto = () => {
    setDiveInto(null);
    setView("aggregated");
    deleteSlicerByKey("dive");
  };

  const handleSlicer = (
    type: keyof filters,
    key: string,
    operation: TOperation,
    values: any
  ) => {
    const newSlicer = { ...slicers };

    if (newSlicer[type]?.and) {
      const existingIndex = newSlicer[type].and.findIndex(
        (item: any) => item.type === key
      );

      if (existingIndex !== -1) {
        if (values.length > 0) {
          newSlicer[type].and[existingIndex] = { type: key, operation, values };
        } else {
          newSlicer[type].and.splice(existingIndex, 1);
        }
      } else {
        newSlicer[type].and.push({ type: key, operation, values });
      }
    } else {
      newSlicer[type] = { and: [{ type: key, operation, values }] };
    }

    setSlicers(newSlicer);
  };
  const deleteSlicerByKey = (key: string) => {
    const newSlicer = { ...slicers };

    if (newSlicer.numericSliceBy?.and) {
      const index = newSlicer.numericSliceBy.and.findIndex(
        (item: any) => item.type === key
      );
      if (index !== -1) {
        newSlicer.numericSliceBy.and.splice(index, 1);
      }
    }
    if (newSlicer.stringSliceBy?.and) {
      const index = newSlicer.stringSliceBy.and.findIndex(
        (item: any) => item.type === key
      );
      if (index !== -1) {
        newSlicer.stringSliceBy.and.splice(index, 1);
      }
    }

    setSlicers(newSlicer);
  };

  return (
    <FilterContext.Provider
      value={{
        groupBy,
        slicers,
        setSlicers,
        dateRange: dateRange ?? "14d",
        handleFilter,
        handleSlicer,
        getValueByKey,
        sliceSelected,
        deleteSlicerByKey,
        view,
        diveIntoTickets,
        diveInto,
        clearDiveInto,
        // handleSlicedSelection,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
