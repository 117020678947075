import { DropdownMenu } from "@radix-ui/themes";
import React from "react";

export const TopicsDropDown = ({ options }: { options: string[] }) => {
  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        Topics
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        {options
          .filter((o) => o !== "Other")
          .map((option) => {
            return (
              <DropdownMenu.Item className="DropdownMenuItem">
                {option}
              </DropdownMenu.Item>
            );
          })}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
