import React, { useContext, useEffect, useState } from "react";
import { FilterContext } from "../context";
import {
  Cross1Icon,
  MagnifyingGlassIcon,
  PaperPlaneIcon,
} from "@radix-ui/react-icons";
import { Button, IconButton, TextField } from "@radix-ui/themes";

export const Keyword = () => {
  const [isSearchActive, setSearchActive] = useState(false);
  const { groupBy, sliceSelected, handleFilter, handleSlicer } =
    useContext(FilterContext);
  const [searchPhrase, setSearchPhrase] = useState("");

  useEffect(() => {
    setSearchPhrase("");
    setSearchActive(false);
  }, [groupBy]);

  const handleSubmit = () => {
    handleSlicer("stringSliceBy", "keyword", "or", [
      { operation: "eq", value: searchPhrase, type: "keyword" },
    ]);
  };

  const handleClear = () => {
    handleSlicer("stringSliceBy", "keyword", "or", []);
    setSearchActive(false);
  };

  return (
    <>
      {isSearchActive ? (
        <TextField.Root
          placeholder={`Slice by keyword`}
          size="1"
          autoFocus
          variant="soft"
          color="gray"
          value={searchPhrase}
          onChange={(e) => setSearchPhrase(e.target.value)}
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
          <TextField.Slot>
            <IconButton
              size="1"
              variant="ghost"
              disabled={!searchPhrase}
              onClick={() => handleSubmit()}
            >
              <PaperPlaneIcon />
            </IconButton>
          </TextField.Slot>
          <TextField.Slot>
            <IconButton size="1" variant="ghost" onClick={() => handleClear()}>
              <Cross1Icon />
            </IconButton>
          </TextField.Slot>
        </TextField.Root>
      ) : (
        <Button
          color="gray"
          variant="ghost"
          highContrast
          size="1"
          onClick={() => setSearchActive(true)}
        >
          <MagnifyingGlassIcon /> Slice by keyword
        </Button>
      )}
    </>
  );
};
