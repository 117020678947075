import styles from "./styles.module.scss";
import { Member } from "../../../accountSetup/types";
import { Card } from "../member";

export const MembersList = ({
  members,
  refetch,
}: {
  members: Member[];
  refetch: () => void;
}) => {
  if (members.length === 0) {
    return null;
  }
  return (
    <div className={styles.container}>
      {members.map((item, index) => {
        return (
          <div key={index}>
            <Card member={item} refetch={refetch} />
          </div>
        );
      })}
    </div>
  );
};
