import { CheckIcon, FaceIcon } from "@radix-ui/react-icons";
import { DropdownMenu } from "@radix-ui/themes";
import React, { useContext, useEffect, useState } from "react";
import { filters, numericValue, TOperation } from "../types";
import { useSearchParams } from "react-router-dom";
import { FilterContext } from "../context";

const fieldsOperationMapper = {
  negative: [{ type: "sentiment", operation: "lte", value: -0.3 }],
  neutral: [
    { type: "sentiment", operation: "gte", value: -0.3 },
    { type: "sentiment", operation: "lte", value: 0.3 },
  ],
  positive: [{ type: "sentiment", operation: "gte", value: 0.3 }],
};

export const SentimentDropDown = () => {
  const [selected, setSelected] = useState<string[]>([]);
  const { handleSlicer, getValueByKey, handleFilter } =
    useContext(FilterContext);
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const values = getValueByKey("sentiment");

    setSelected(values ? values.split(",") : []);
  }, [searchParams]);

  const handleSlice = (key: string) => {
    let fields = [];
    if (selected.includes(key)) {
      fields = selected.filter((item) => item !== key);
    } else {
      fields = [...selected, key];
    }

    handleFilter("sentiment", fields.join(","));
    setSelected(fields);
    // handleSlicedSelection("sentiment", fields.join(","));

    let isAnd = fields.length === 1 && fields[0] === "neutral";
    let values: any[] = [];
    let numbers: any = {};
    fields?.forEach((x: any) => {
      fieldsOperationMapper[x as keyof typeof fieldsOperationMapper].forEach(
        (filter: Pick<numericValue, "value">) => {
          if (numbers[filter.value]) {
            numbers[filter.value] = numbers[filter.value] + 1;
          } else {
            numbers[filter.value] = 1;
          }
        }
      );

      values = [
        ...values,
        ...fieldsOperationMapper[x as keyof typeof fieldsOperationMapper],
      ];
    });

    const filteredValues = values.filter((x) => {
      if (numbers[x.value] === 2) {
        return false;
      }
      return true;
    });

    handleSlicer(
      "numericSliceBy",
      "sentiment",
      isAnd ? "and" : "or",
      filteredValues
    );
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        <FaceIcon />
        Sentiment
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        <DropdownMenu.Item
          className="DropdownMenuItem"
          onClick={() => handleSlice("positive")}
        >
          {selected.includes("positive") && <CheckIcon />}
          Positive
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className="DropdownMenuItem"
          onClick={() => handleSlice("neutral")}
        >
          {selected.includes("neutral") && <CheckIcon />}
          Neutral
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className="DropdownMenuItem"
          onClick={() => handleSlice("negative")}
        >
          {selected.includes("negative") && <CheckIcon />}
          Negative
        </DropdownMenu.Item>
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
