import React, { useState } from "react";
import { useReleases } from "../simulator/context/release";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Release, SimulatorChannel } from "../simulator/types";
import { Button, Card, Heading } from "@radix-ui/themes";

import styles from "./styles.module.scss";
import { Dot, Input } from "@geist-ui/core";
import { Send, Slack } from "@geist-ui/icons";
import {
  ArrowRight,
  BookIcon,
  Pencil,
  SendIcon,
  SparkleIcon,
} from "lucide-react";
import { LightningBoltIcon } from "@radix-ui/react-icons";

const iconsMapper = {
  helpcenter: <BookIcon />,
  autonomous_ticket_event: <SparkleIcon />,
  autonomous_quackchat: <LightningBoltIcon />,
  slackbot: <Slack />,
  copilot: <SendIcon />,
};
const channelMapper = {
  helpcenter: {
    label: "Help center chatbot",
    description:
      "Enhance your customer experience with our AI solution that will answer ",
  },
  autonomous_ticket_event: {
    label: "",
    description: "",
  },
  autonomous_quackchat: {
    label: "",
    description: "",
  },
  slackbot: {
    label: "",
    description: "",
  },
  copilot: {
    label: "",
    description: "",
  },
};
export const Simulators = () => {
  const { simulators, handleCreateSimulator } = useReleases();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Heading> AI Agents</Heading>
        <Button
          color="gray"
          variant="solid"
          onClick={handleCreateSimulator}
          highContrast
        >
          Create Agent
        </Button>
      </div>
      <Card
        variant="classic"
        style={{ gap: "16px", display: "flex", flexDirection: "column" }}
      >
        {simulators?.map((r) => (
          <SimulatorItem r={r} key={r.id} />
        ))}
      </Card>
    </div>
  );
};

const SimulatorItem = ({
  r,
}: {
  r: {
    id: number;
    name: string;
    releases: Release[];
    channel: SimulatorChannel;
    publishedReleaseId?: number;
  };
}) => {
  const [isEdit, setEdit] = useState(false);
  const [value, setValue] = useState(r.name);

  const { handleUpdateSimulator, setChosenSimulatorId } = useReleases();

  const navigate = useNavigate();
  const lastUpdateAt = r.releases?.reduce(
    (latest: Date, release: { updatedAt: Date }) => {
      const releaseDate = new Date(release.updatedAt);
      return releaseDate > latest ? releaseDate : latest;
    },
    new Date(0)
  );

  const draftRelease = r.releases.find(
    (release) => release.status === "draft" && !release.publishedAt
  );
  const publishedRelease = r.releases.find(
    (release) => release.id === r.publishedReleaseId
  );

  const calculateUpdatedInstructions = (
    draft?: Release,
    published?: Release
  ) => {
    // if (!draft || !published) return 0;
    if (!draft) return 0;

    const draftInstructions = JSON.parse(draft.releasePolicy);
    if (!published) return draftInstructions.length - 4;
    const publishedInstructions = JSON.parse(published.releasePolicy);
    const updatedInstructions = draftInstructions.filter(
      (draftInst: any) =>
        !publishedInstructions.some(
          (pubInst: any) =>
            pubInst.content === draftInst.content &&
            pubInst.subTopic === draftInst.subTopic &&
            pubInst.topic === draftInst.topic &&
            pubInst.type === draftInst.type
        )
    );
    const deletedInstructions = publishedInstructions.filter(
      (draftInst: any) =>
        !draftInstructions.some(
          (pubInst: any) =>
            pubInst.content === draftInst.content &&
            pubInst.subTopic === draftInst.subTopic &&
            pubInst.topic === draftInst.topic &&
            pubInst.type === draftInst.type
        )
    );
    return updatedInstructions.length + deletedInstructions.length;
  };

  const updatedInstructionsCount = calculateUpdatedInstructions(
    draftRelease,
    publishedRelease
  );

  return (
    <div
      className={styles.simulator}
      onClick={() => {
        if (!isEdit) {
          navigate(`/simulator/${r.id}`);
        }
      }}
    >
      <div>
        <span
          className={styles.editableTitle}
          onClick={(e) => {
            e.stopPropagation();
            setEdit(true);
            setChosenSimulatorId(r.id);
          }}
        >
          {isEdit ? (
            <Input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              crossOrigin={undefined}
              autoFocus
              onBlur={(e) => {
                handleUpdateSimulator(r.id, value);
                e.stopPropagation();
                setTimeout(() => {
                  setEdit(false);
                }, 300);
              }}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className={styles.input}
            />
          ) : (
            <Heading as="h6" size="3">
              {r.name}
            </Heading>
          )}
          <button>
            <Pencil />
          </button>
        </span>
        <Heading as="h6" size="1" weight="light">
          Last release {dayjs(lastUpdateAt).format("MMM DD,YYYY")}
        </Heading>
      </div>
      <label>
        <Send />
        {r.releases.length - 1} Releases
      </label>
      <label>
        <Dot type={updatedInstructionsCount === 0 ? "default" : "warning"} />
        {updatedInstructionsCount} Updates{" "}
      </label>
      <label>
        {iconsMapper[r.channel]}
        {r.channel}
      </label>
    </div>
  );
};
