import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import request, { RequestDocument } from "graphql-request";
import { useMutation, useQuery } from "react-query";
import { IdToken } from "@auth0/auth0-spa-js";

export const useGraphQLAuthorizedQuery = ({
  queryKey,
  url,
  document,
  variables,
  enabled,
  cacheTime = 5 * 60 * 1000,
}: {
  queryKey: any[];
  url: string;
  document: RequestDocument;
  variables: { [key: string]: any };
  enabled: boolean;
  cacheTime?: number;
}) => {
  const [tokenId, setToken] = useState<IdToken | undefined>(undefined);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims();
      setToken(token);
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  const headers = {
    "Content-Type": "application/json",
    authorization: tokenId?.__raw ? tokenId.__raw : "",
  };

  return useQuery<any>({
    queryKey,
    queryFn: async () => {
      return await request(url, document, variables, headers);
    },
    enabled: enabled && isAuthenticated && !!tokenId,
    cacheTime: cacheTime,
  });
};

type TRequestOptions = {
  method: string;
  headers: {
    "Content-Type": string;
    Authorization: string;
  };
  body?: any;
};

export const useAuthorizedMutation = ({
  url,
  method = "POST",
}: {
  url: string;
  method?: string;
}) => {
  const [tokenId, setToken] = useState<IdToken | undefined>(undefined);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims();
      setToken(token);
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  const authToken = tokenId?.__raw ? tokenId.__raw : "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };

  return useMutation({
    mutationFn: async (data: Record<string, any> | null) => {
      if (!data) {
        throw new Error("useMutation: no data supplied");
      }
      const options: TRequestOptions = {
        method: method,
        headers,
      };
      if (method !== "GET") {
        options.body = JSON.stringify(data);
      }

      const res = await fetch(url, options);
      return res.json();
    },
  });
};

export const useAuthorizedQuery = ({
  url,
  queryKey,
  enabled,
  method = "GET",
  cacheTime = 5 * 60 * 1000,
}: {
  url: string;
  queryKey: string;
  enabled: boolean;
  method?: string;
  cacheTime?: number;
}) => {
  const [tokenId, setToken] = useState<IdToken | undefined>(undefined);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims();
      setToken(token);
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  const authToken = tokenId?.__raw ? tokenId.__raw : "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };

  return useQuery({
    queryKey,
    queryFn: async (data: Record<string, any> | null) => {
      if (!data) {
        throw new Error("useQuery: no data supplied");
      }
      const options: TRequestOptions = {
        method: method,
        headers,
      };
      if (method !== "GET") {
        options.body = JSON.stringify(data);
      }
      const res = await fetch(url, options);
      return res.json();
    },
    enabled: enabled && isAuthenticated && !!tokenId,
    cacheTime: cacheTime,
  });
};
