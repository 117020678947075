import React, { useMemo } from "react";
import cx from "classnames";

import {
  Integration,
  IntegrationSetupStatus,
} from "../../../accountSetup/types";

import styles from "./styles.module.scss";

export const Card = ({
  integration,
  onClick,
  selected,
}: {
  integration: Integration;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  selected: boolean;
}) => {
  const statusColor = useMemo(() => {
    return {
      [IntegrationSetupStatus.COMPLETED]: styles.completed,
      [IntegrationSetupStatus.IN_PROGRESS]: styles.inProgress,
      [IntegrationSetupStatus.NOT_AVAILABLE]: styles.notAvalible,
      [IntegrationSetupStatus.UNDEFINED]: styles.undefined,
    }[integration.status ?? IntegrationSetupStatus.UNDEFINED];
  }, [integration.status]);

  return (
    <div
      className={cx(styles.container, {
        [styles.selected]: selected,
        [styles.disabled]: !integration.isEnabled,
      })}
      onClick={(e) => {
        if (integration.isEnabled) {
          onClick(e);
        }
      }}
    >
      <div className={cx(styles.status, statusColor)} />
      <div>{integration.name}</div>
    </div>
  );
};
