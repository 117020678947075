import { forwardRef, PropsWithChildren, useContext, useMemo } from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { Theme } from "@radix-ui/themes";
import cx from "classnames";
import styles from "./Select.module.scss";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { ThemeContext } from "../../../components/Theme";

export type SelectOption = {
  label: string;
  value: string;
};

interface SelectItemProps {
  value: string;
  selected?: boolean;
  className?: string;
}

const SelectItem = forwardRef(function SelectItem(
  { children, value, selected = false, className }: PropsWithChildren<SelectItemProps>,
  forwardedRef: React.Ref<HTMLDivElement>
) {
  return (
    <SelectPrimitive.Item
      ref={forwardedRef}
      value={value}
      className={cx(styles.Item, className)}
      aria-selected={selected}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});

interface SelectProps {
  options?: SelectOption[];
  value: string | undefined;
  onChange: (value: string) => void;
}

function Select({ options = [], value, onChange }: SelectProps) {
  const { isDarkMode } = useContext(ThemeContext);

  const selectedValue = useMemo(() => {
    return options.find((option) => option.value === value)?.label;
  }, [options, value]);

  return (
    <SelectPrimitive.Root
      value={selectedValue}
      onValueChange={onChange}
    >
      <SelectPrimitive.Trigger className={styles.Trigger}>
        <SelectPrimitive.Value placeholder="Select …" aria-label={selectedValue}>
          {selectedValue}
        </SelectPrimitive.Value>

        <SelectPrimitive.Icon className={styles.Icon}>
          <ChevronDownIcon />
        </SelectPrimitive.Icon>
      </SelectPrimitive.Trigger>

      <SelectPrimitive.Portal>
        <Theme>
          <SelectPrimitive.Content
            position={"popper"}
            sideOffset={5}
            className={cx(styles.Content, {
              [styles.tooltip_light]: !isDarkMode,
              [styles.tooltip_dark]: isDarkMode,
            })}
          >
            <SelectPrimitive.Viewport className={styles.Viewport}>
              {options.map((option) => (
                <SelectItem key={option.value} value={option.value} selected={option.value === value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectPrimitive.Viewport>
          </SelectPrimitive.Content>
        </Theme>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
}

export default Select;
