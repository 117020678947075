import React, { Fragment, ReactNode, useEffect, useRef, useState, useContext } from "react";
import { useLayer, Arrow } from "react-laag";
import { PlacementType } from "react-laag/dist/PlacementType";
import cx from "classnames";
import { ChevronRight } from "@geist-ui/icons";
import { Toggle } from "@geist-ui/core";
import { ThemeContext } from "../Theme";

import styles from "./styles.module.scss";

export function PopoverMenu({
  options,
  handleClick,
  isArrow = false,
  placement = "top-end",
  children,
  trigger,
  onClick = () => {},
  openHover = false,
  handleTriggerClick = undefined,
  closeDelay = 500,
  disableDisappearance = false,
  disableOverflowScroll = false,
}: {
  options: {
    key: string;
    label: string | ReactNode;
    isActive?: boolean;
    isBoolean?: boolean;
    isSelected?: boolean;
    subOptions?: {
      key: string;
      label: string | ReactNode;
      isActive?: boolean;
      isBoolean?: boolean;
      isSelected?: boolean;
    }[];
  }[];
  handleClick: (v: string) => void;
  isArrow?: boolean;
  placement?: PlacementType;
  children: ReactNode;
  trigger?: (isOpen: boolean) => void;
  onClick?: (isOpen: boolean) => void;
  openHover?: boolean;
  handleTriggerClick?: () => void;
  closeDelay?: number | null;
  disableDisappearance?: boolean;
  disableOverflowScroll?: boolean;
}) {
  const { isDarkMode } = useContext(ThemeContext);

  const [isOpen, setOpen] = useState(false);
  let openTimeout = useRef<NodeJS.Timeout | null>(null);
  let closeTimeout = useRef<NodeJS.Timeout | null>(null);
  let popoverRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (trigger) {
      trigger(isOpen);
    }
  }, [isOpen, trigger]);

  function close() {
    if (openTimeout.current) clearTimeout(openTimeout.current!);
    closeTimeout.current = setTimeout(() => {
      if (
        popoverRef.current &&
        popoverRef.current.contains(document.activeElement)
      ) {
        return;
      }
      setOpen(false);
    }, closeDelay!);
  }

  function open() {
    if (closeTimeout.current) clearTimeout(closeTimeout.current!);
    openTimeout.current = setTimeout(() => setOpen(true), 100);
  }

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => console.log("onOutsideClick"),
    onDisappear: !disableDisappearance ? close : () => {},
    overflowContainer: false,
    auto: true,
    placement,
    triggerOffset: 6,
    containerOffset: 24,
    arrowOffset: 0,
  });

  const hoverProps = openHover
    ? {
        onMouseEnter: open,
        onMouseLeave: close,
      }
    : {};

  return (
    <Fragment>
      <span
        className="flex justify-center"
        {...triggerProps}
        {...hoverProps}
        onClick={(e) => {
          e.stopPropagation();
          if (!openHover) {
            setOpen(!isOpen);
            onClick(!isOpen);
          } else if (handleTriggerClick) {
            handleTriggerClick();
          }
        }}
      >
        {children}
      </span>
      {renderLayer(
        <div
          ref={popoverRef}
          onMouseEnter={() => clearTimeout(closeTimeout.current!)}
          onMouseLeave={close}
        >
          {isOpen && (
            <div
              {...layerProps}
              style={{ ...layerProps.style, zIndex: 200000 }}
            >
              <div
                className={cx(styles.container, {
                  [styles.disableOverflowScroll]: disableOverflowScroll,
                  [styles.dark_mode]: isDarkMode,
                  [styles.light_mode]: !isDarkMode,
                })}
              >
                {options.map((o) => {
                  if (o.subOptions) {
                    return (
                      <PopoverMenu
                        key={o.key}
                        options={o.subOptions}
                        handleClick={(key) => handleClick(`${o.key}::${key}`)}
                        placement="left-start"
                        openHover
                        handleTriggerClick={() => {}}
                        disableDisappearance
                        closeDelay={150}
                      >
                        {" "}
                        <button
                          className={cx(styles.button, {
                            [styles.active]: o.isActive,
                          })}
                          key={o.key}
                        >
                          <div>{o.label}</div>
                          <ChevronRight />
                        </button>
                      </PopoverMenu>
                    );
                  } else {
                    return (
                      <button
                        className={cx(styles.button, {
                          [styles.active]: o.isActive,
                          [styles.selected]: o.isSelected ?? false,
                        })}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(o.key);
                          setOpen(false);
                        }}
                        key={o.key}
                      >
                        <div>{o.label}</div>
                        {o.isBoolean && (
                          <Toggle initialChecked checked={o.isActive} />
                        )}
                      </button>
                    );
                  }
                })}
                {isArrow && (
                  <Arrow
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    {...arrowProps}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}
