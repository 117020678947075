import React, { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import cx from "classnames";
import { ArrowUp, ChevronLeft, ArrowDown } from "@geist-ui/icons";
import { Badge } from "@radix-ui/themes";
import { Button, Loading, Tooltip } from "@geist-ui/core";
import { IoSparkles, IoInformationCircleOutline } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetArticleOpportunities,
  useGetExtendedArticleDetails,
} from "../../api/useWizard";
import {
  ArticleDetails,
  ArticleExtendedDetails,
  Opportunity,
} from "../articleWizard/index.types";
import GlobalLinkIcon from "../../assets/global-link.svg";
import { MerticItemProps } from "./index.types";
import { useGetTopics } from "../../api/useGetTopics";
import { TTopic } from "../samplingById/samplingById.types";
import styles from "./styles.module.scss";

function MetricItem({ label, value, tooltipText }: MerticItemProps) {
  const valueComponent = React.isValidElement(value) ? (
    <>{value}</>
  ) : (
    <div className={styles.metricsItemValue}>{value}</div>
  );
  return (
    <div className={styles.merticItemContainer}>
      {valueComponent}
      <LabelWithInfoIcon label={label} tooltipText={tooltipText} />
    </div>
  );
}

function ImproveButton({
  topicId,
  articleId,
}: {
  topicId: string;
  articleId: string;
}) {
  return (
    <Link to={`/knowledge-management/${topicId}/wizard/${articleId}`}>
      <Button
        auto
        icon={<IoSparkles />}
        type="secondary"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        Improve article
      </Button>
    </Link>
  );
}

function OriginalLinkContainer({ href = "" }: { href: string }) {
  return (
    <a target="_blank" href={href} className={styles.originalLinkContainer}>
      <img src={GlobalLinkIcon} alt="global-link" />
      <span>Original Article</span>
    </a>
  );
}

function LabelWithInfoIcon({ label = "", tooltipText = "", info = "" }) {
  return (
    <div className={styles.labelWithInfoContainer}>
      <span className={styles.label}>{label}</span>
      {!!tooltipText && (
        <Tooltip className={styles.tooltip} content={info} text={tooltipText}>
          <IoInformationCircleOutline />
        </Tooltip>
      )}
    </div>
  );
}

function TrafficLightDiv() {
  return (
    <div className={styles.trafficLightDiv}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

function MetaDataSection({
  createdAt,
  updatedAt,
  author,
  topic,
}: Partial<ArticleDetails & { topic: string }>) {
  return (
    <div className={styles.metadataContainer}>
      <div className={styles.metadataItem}>
        <span className={styles.label}>Created at</span>
        <span className={styles.label}>
          {dayjs(createdAt).format("DD MMM HH:MM a")}
        </span>
      </div>
      <div className={styles.metadataItem}>
        <span className={styles.label}>Last Updated</span>
        <span className={styles.label}>
          {dayjs(updatedAt).format("DD MMM HH:MM a")}
        </span>
      </div>
      <div className={styles.metadataItem}>
        <span className={styles.label}>Author</span>
        <span className={styles.label}>{author}</span>
      </div>
      <div className={styles.metadataItem}>
        <span className={styles.label}>Topic</span>
        <Badge size="2" color="gray">
          {topic}
        </Badge>
      </div>
    </div>
  );
}

function ArticleAnalyticsSection({
  topicId,
  articleId,
  ticketConverage,
  opportunities,
}: {
  topicId: string;
  articleId: string;
  ticketConverage: number;
  opportunities: Opportunity[];
}) {
  return (
    <div className={styles.articleAnalysisContainer}>
      <LabelWithInfoIcon
        tooltipText="Tickets coverage score"
        label="Tickets Coverage Score"
      />
      <div className={styles.ticketConverageValue}>
        <span>{ticketConverage}</span>
        <TrafficLightDiv />
      </div>
      <div>
        <span className={styles.label}>Gap analysis</span>
        <span>
          <ul>
            {opportunities.map((opportunity) => (
              <li>{opportunity.label}</li>
            ))}
          </ul>
        </span>
      </div>
      <ImproveButton topicId={topicId} articleId={articleId} />
    </div>
  );
}

function TrendingVolLabel({ value }: { value: number }) {
  const size = 16;
  const shouldRenderDirectionIndicator = value !== 0;
  return (
    <div
      className={cx(styles.trendingVolValueContainer, {
        [styles.success]: value > 0,
        [styles.error]: value < 0,
      })}
    >
      {shouldRenderDirectionIndicator &&
        (value > 0 ? <ArrowUp size={size} /> : <ArrowDown size={size} />)}
      {value}%
    </div>
  );
}

export const ArticlePage = () => {
  const { articleId, topicId } = useParams<{
    articleId: string;
    topicId: string;
  }>();
  const navigate = useNavigate();

  const { data: articleDetailsData, isLoading: isArticleDetailsLoading } =
    useGetExtendedArticleDetails({
      articleId: articleId || "",
      enabled: !!articleId,
    });

  const { data: opportunitiesData, isLoading: isOpportunitiesLoading } =
    useGetArticleOpportunities({
      articleId: articleId || "",
      enabled: !!articleId,
    });

  const { data: topics, isLoading: isTopicsLoading } = useGetTopics();

  const opportunities = opportunitiesData as Opportunity[];
  const articleDetails = articleDetailsData?.[0] as ArticleExtendedDetails;

  useEffect(() => {
    if (topicId && !articleDetails?.topicIds.includes(topicId)) {
      navigate(
        `/knowledge-management/${articleDetails?.topicIds[0]}/article/${articleId}`
      );
    }
  }, [topicId, articleDetails?.topicIds]);

  const { topic } = useMemo(() => {
    return (
      topics.find((t: TTopic) => {
        return t.id === parseInt(articleDetails?.topicIds[0]);
      }) ?? {}
    );
  }, [topics, articleDetails]);

  const getTrendingVolume = () => {
    if (articleDetails) {
      let { currentUsage, compareUsage } = articleDetails;

      if (compareUsage === currentUsage) return 0;
      if (!compareUsage) return 100;

      const trendDirection = currentUsage > compareUsage ? 1 : -1;
      const usageDiff = Math.abs(currentUsage - compareUsage);
      return Math.round((usageDiff / compareUsage) * 100 * trendDirection);
    }
    return 0;
  };

  function getTotalCoveredTickets() {
    const suggestedArticleOpportunityIds = new Set(
      articleDetails?.suggestedArticles?.flatMap(
        (article) => article.opportunityIds
      ) || []
    );

    const totalTicketsCovered = opportunities
      ?.filter(
        (opportunity) =>
          suggestedArticleOpportunityIds.has(opportunity.id) &&
          opportunity.transaction.commonTransactionEval.isCovered
      )
      .reduce(
        (acc, opportunity) => acc + opportunity.transaction.tickets.length,
        0
      );

    return totalTicketsCovered;
  }

  const isLoading =
    isOpportunitiesLoading || isArticleDetailsLoading || isTopicsLoading;
  const isDataReady = !!(articleDetails && opportunities && topics);

  if (isLoading || !isDataReady) {
    return <Loading />;
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <div className={styles.title}>
          <ChevronLeft
            className={styles.backArrow}
            onClick={() => navigate(`/knowledge-management/${topicId}`)}
          />
          <h3>{articleDetails.title}</h3>
        </div>
        <div className={styles.cta}>
          <OriginalLinkContainer href={articleDetails.url} />
          <ImproveButton
            topicId={topicId ?? ""}
            articleId={articleDetails.articleId}
          />
        </div>
      </div>
      <div className={styles.mertricsContainer}>
        <MetricItem label="Status" value={articleDetails.status} />
        <MetricItem
          label="Unique Visitors"
          value={articleDetails.viewsWithoutDuplications}
        />
        <MetricItem label="Tickets Vol." value={articleDetails.currentUsage} />
        <MetricItem
          tooltipText="Text text tesxt"
          label="Tickets Vol. Trend"
          value={<TrendingVolLabel value={getTrendingVolume()} />}
        />
      </div>
      <div className={styles.gapBox}>
        <div className={styles.articleContentContainer}>
          <h3>{articleDetails.title}</h3>
          <p className={styles.articleContent}>
            {articleDetails.content || "Can't find article"}
          </p>
        </div>
        <div className={cx(styles.rightSectionContainer, styles.gapBox)}>
          <ArticleAnalyticsSection
            topicId={topicId ?? ""}
            opportunities={opportunities ?? []}
            ticketConverage={getTotalCoveredTickets()}
            articleId={articleDetails.articleId}
          />
          <MetaDataSection
            createdAt={articleDetails.createdAt}
            updatedAt={articleDetails.updatedAt}
            author={articleDetails.author}
            topic={topic}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
