import { PropsWithChildren, useEffect, useState } from "react";
import cx from "classnames";
import { SharedReportValues } from "./model";
import SharedReportHeader from "./components/SharedReportHeader";
import SharedReportTopMetrics from "./components/SharedReportTopMetrics";
import SharedReportTicketScoreTable from "./components/SharedReportTicketScoreTable";
import SharedReportTicketScoreOverTime from "./components/SharedReportTicketScoreOverTime";
import SharedReportAreasForImprovement from "./components/SharedReportAreasForImprovement";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Button, TextField } from "@radix-ui/themes";
import { params } from "../../params";
import { useTheme } from "../../components/Theme";
import logoImg from "../../assets/logo.png";
import { ClipboardIcon } from "@radix-ui/react-icons";
import { CheckIcon } from "@radix-ui/react-icons";

const SharedReportSectionTitle = ({ title }: { title: string }) => {
  return <div className={styles.sectionTitle}>{title}</div>;
};

const SharedReportSection = ({ children, title, className }: PropsWithChildren<{ title?: string, className?: string }>) => {
  return (
    <div className={cx(styles.section, className)}>
      {title && <SharedReportSectionTitle title={title} />}

      <div className={styles.sectionContent}>
        {children}
      </div>
    </div>
  );
};

const SharedReportCopyLink = () => {
  const copyLink = `${window.location.origin}${window.location.pathname}`;

  const [isCopied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator?.clipboard?.writeText(copyLink);

    setCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  return (
    <div className={styles.copyLink}>
      <TextField.Root size="1" value={copyLink}/>

      <Button 
        color={isCopied ? "green" : "gray"}
        variant={isCopied ? "outline" : "solid"}
        size="1"   
        highContrast 
        onClick={handleCopyLink}
      >
        {isCopied ? <CheckIcon /> : <ClipboardIcon />}
        Copy Link
      </Button>
    </div>
  )
};

const SharedReportComponent = ({
  report,
  isLoading,
}: {
  report: SharedReportValues | undefined;
  isLoading: boolean;
}) => {
  if (!report || isLoading) {
    return null;
  }

  const { metadata, agentName, avgScore, numberOfTickets, lowestScoreTickets, highestScoreTickets, halfYearAgentTicketScoresAvg, badQaScores } = report;
  
  return (
    <div className={styles.sharedReportContainer}>
      <div className={styles.sharedReportContainerHeader}>
        <img
          src={logoImg}
          alt="Quack Logo"
        />

        <SharedReportCopyLink />
      </div>
      
      <div className={styles.sharedReport}>
        <SharedReportHeader metadata={metadata} agentName={agentName} />

        <SharedReportTopMetrics avgScore={avgScore} numberOfTickets={numberOfTickets} />

        <SharedReportSection title={"Lowest Score Tickets"}>
          <SharedReportTicketScoreTable data={lowestScoreTickets} />
        </SharedReportSection>

        <SharedReportSection title={"Highest Score Tickets"}>
          <SharedReportTicketScoreTable data={highestScoreTickets} />
        </SharedReportSection>

        {/* <SharedReportSection title="Avg. QA Score Over Time" className={styles.ticketScoreOverTime}>
          <SharedReportTicketScoreOverTime data={halfYearAgentTicketScoresAvg} />
        </SharedReportSection> */}

        <SharedReportSection title="Areas for Improvement" className={styles.areasForImprovementSection}>
          <SharedReportAreasForImprovement data={badQaScores} />
        </SharedReportSection>
      </div>
    </div>
  );  
};

export const sharedReportRouteUri = "/shared-report";
export const getSharedReportUrl = (reportId: string) => `${sharedReportRouteUri}/${reportId}`;

function useGetSharedReport(reportId?: string) {
  return useQuery<SharedReportValues>({
    queryKey: ["shared-report", reportId],
    queryFn: async () => {
      const res = await fetch(`${params.SHARED_REPORT_URL}/${reportId}.json`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      return res.json();
    },
  });
}

export const SharedReport = () => {
  const { reportId } = useParams();
  const { data, isLoading } = useGetSharedReport(reportId);
  const { isDarkMode, toggleTheme } = useTheme();

  useEffect(() => {
    if (isDarkMode) {
      toggleTheme();

      return () => toggleTheme();
    }
  }, []);

  return <SharedReportComponent report={data} isLoading={isLoading} />;
};
