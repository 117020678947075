import React, { useContext, useEffect, useState } from "react";
import { FilterContext } from "../context";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Button, TextField } from "@radix-ui/themes";

export const Search = ({
  searchPhrase,
  setSearchPhrase,
}: {
  searchPhrase: string;
  setSearchPhrase: (value: string) => void;
}) => {
  const [isSearchActive, setSearchActive] = useState(false);
  const { groupBy } = useContext(FilterContext);

  useEffect(() => {
    setSearchPhrase("");
    setSearchActive(false);
  }, [groupBy]);

  return (
    <>
      {isSearchActive ? (
        <TextField.Root
          placeholder={`Search in ${groupBy}`}
          size="1"
          autoFocus
          variant="soft"
          color="gray"
          value={searchPhrase}
          onChange={(e) => setSearchPhrase(e.target.value)}
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>
      ) : (
        <Button
          color="gray"
          variant="ghost"
          highContrast
          size="1"
          onClick={() => setSearchActive(true)}
        >
          <MagnifyingGlassIcon /> Find by
        </Button>
      )}
    </>
  );
};
