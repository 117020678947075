import {
  Box,
  Archive,
  Home,
  Layers,
  Settings,
  Speaker,
  Users,
} from "@geist-ui/icons";
import { BsLightningCharge } from "react-icons/bs";
import { VscGraphLine } from "react-icons/vsc";
import { Crosshair } from "@geist-ui/icons";
import { Compass } from "@geist-ui/icons";

import { PiShapes } from "react-icons/pi";
import { ReactNode } from "react";
import {
  BackpackIcon,
  EyeOpenIcon,
  FileTextIcon,
  RocketIcon,
  StackIcon,
  TokensIcon,
  VideoIcon,
} from "@radix-ui/react-icons";

export enum ENavigate {
  HOME = "home",
  ASSESSMENT = "topics",
  TICKETS = "tickets",
  ACTIVATION = "activation",
  SETTINGS = "settings",
  KNOWLEDGE = "knowledge-management",
  SAMPLING = "sampling",
  PERFORMANCE = "teams",
  VOC = "categories",
  SIMULATOR = "resolution",
  SESSIONS_REPORTS = "sessions-reports",
  CUSTOMERS = "customers",
}

export const PagesIcons: Record<ENavigate, ReactNode> = {
  [ENavigate.HOME]: <Home />,
  [ENavigate.TICKETS]: <Layers />,
  [ENavigate.ACTIVATION]: <BsLightningCharge />,
  [ENavigate.SETTINGS]: <Settings />,
  [ENavigate.SESSIONS_REPORTS]: <VideoIcon />,
  //
  [ENavigate.CUSTOMERS]: <BackpackIcon />,
  [ENavigate.PERFORMANCE]: <Users />,
  [ENavigate.VOC]: <TokensIcon />,
  [ENavigate.ASSESSMENT]: <StackIcon />,
  [ENavigate.SAMPLING]: <EyeOpenIcon />,
  [ENavigate.SIMULATOR]: <RocketIcon />,
  [ENavigate.KNOWLEDGE]: <FileTextIcon />,
};
