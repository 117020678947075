import {
  ComponentBooleanIcon,
  Half1Icon,
  ComponentPlaceholderIcon,
  ComponentInstanceIcon,
  CommitIcon,
} from "@radix-ui/react-icons";

import { ComponentNoneIcon } from "@radix-ui/react-icons";

export const categoriesMapper = {
  bug: {
    label: "Bug",
    icon: <ComponentNoneIcon />,
  },
  feedback: {
    label: "Feedback",
    icon: <ComponentBooleanIcon />,
  },
  general_request: {
    label: "Request",
    icon: <ComponentInstanceIcon />,
  },
  feature_request: {
    label: "Feature Request",
    icon: <ComponentPlaceholderIcon />,
  },
  information_or_education: {
    label: "Educational",
    icon: <Half1Icon />,
  },
  troubleshooting: {
    label: "Troubleshooting",
    icon: <CommitIcon />,
  },
  other: {
    label: "Other",
    icon: <></>,
  },
};
